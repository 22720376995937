import React, { useEffect, useState } from "react";
import PhotoApis from "../../../../apis/masseur/photoApis";
import Loading from "../../../../components/general/Loading";
import { IconButton, Tooltip } from "@mui/material";
import {
  Info as InfoIcon,
  Delete as DeleteIcon,
  AddOutlined as AddOutlinedIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";

export default function MyPhoto() {
  const { t } = useTranslation();

  const { isPhotoLoading, getPhotos, addPhoto, deletePhoto } = PhotoApis();
  const [errorMessage, setErrorMessage] = useState(null);
  const [photos, setPhotos] = useState([]);

  const addMasseurPhoto = async (e) => {
    const { files } = e.target;
    const response = await addPhoto(files[0]);

    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setPhotos((prevState) => [response.data, ...prevState]);
    }
  };

  const fetchPhotos = async () => {
    const response = await getPhotos();
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setPhotos(response.data);
    }
  };

  const removePhoto = async (id, index) => {
    const response = await deletePhoto(id);
    if (response.data) {
      photos.splice(index, 1);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  if (errorMessage) return null;

  return (
    <main className="myPhoto">
      {isPhotoLoading ? (
        <div className="circularLoading">
          <Loading />
        </div>
      ) : (
        <React.Fragment>
          <h2 className="myPhoto__title">
            {t("masseur.myPhoto.myPhotoHeader")}
          </h2>
          <section className="myPhoto__container">
            <div className="myPhoto__container__body">
              <label for="image" className="myPhoto__container__body__label">
                <AddOutlinedIcon className="myPhoto__container__body__label__icon" />
                <p> {t("masseur.myPhoto.addImages")}</p>
              </label>
              {photos.map((photo, index) => (
                <figure className="myPhoto__container__body__image">
                  <div className="myPhoto__container__body__image__tooltip">
                    <Tooltip title={t("client.title.Delete")}>
                      <IconButton>
                        <DeleteIcon
                          sx={{ color: "white" }}
                          onClick={() => removePhoto(photo.id, index)}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        photo.approved
                          ? t("client.title.Approved")
                          : t("client.title.NotApproved")
                      }
                    >
                      <IconButton>
                        <InfoIcon sx={{ color: "white" }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <img src={photo.image_url} alt="" />
                </figure>
              ))}
              <input
                type="file"
                id="image"
                name="image"
                onChange={addMasseurPhoto}
              />
            </div>
          </section>
        </React.Fragment>
      )}
    </main>
  );
}
