import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useToast } from "../context/ToastContext";
import { useLocation } from "react-router-dom";
import ProfileApis from "../apis/masseur/ProfileApis";
import Loading from "../components/general/Loading";

export default function MasseurPrivateRoute({ children }) {
  const { isProfileLoading, currentProfile } = ProfileApis();

  const { showToast } = useToast();
  const { masseur, isMasseurAuthenticated } = useSelector(
    (state) => state.masseurAuth
  );
  const navigate = useNavigate();
  const access_token = Cookies.get("masseur-access-token");
  const location = useLocation();
  const { hash, pathname, search } = location;
  const path = pathname.split("/")[-1];
  const accountState =
    masseur && masseur.account_trial
      ? masseur.account_trial
      : masseur?.subscribed;

  const getCurrentProfile = async () => {
    await currentProfile();
  };
  useEffect(() => {
    getCurrentProfile();
  }, []);

  if (isProfileLoading) return <Loading />;

  if (
    isMasseurAuthenticated &&
    access_token &&
    masseur.account_verified &&
    masseur.active &&
    masseur.initial_profile_setup == "completed" &&
    accountState
  ) {
    return children;
  } else {
    if (
      masseur &&
      !masseur.active &&
      masseur.initial_profile_setup != "completed"
    ) {
      navigate(`/masseur/profileSetup/${masseur.setup_stage}`);
      return;
    }
    navigate("/signin");
    return;
  }
}
