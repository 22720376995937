import React, {  useState } from "react";
import MasseurAuth from "../../../apis/MasseurAuth";
import SigninForm from "./SigninForm";

export default function MasseurSignin() {
  const { masseurSignin } = MasseurAuth();

  const [masseur, setMasseur] = useState({
    email: null,
    password: null,
  });

  const onSubmit = async (data) => {
    await masseurSignin(data);
  };

  return (
    <SigninForm
      user={masseur}
      setUser={setMasseur}
      onSubmit={onSubmit}
    />
  );
}
