import React, { useState, useMemo, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GreenInput from "../Form/GreenInput";
import Loading from "../general/Loading";
import { add } from "date-fns";
import { useAdContext } from "../../context/AdContext";

// Define the validation schema

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
});

const AboutAd = ({ adData, setAdData, handleTabsChange }) => {
  const { t } = useTranslation();
  const editorRef = useRef(null);
  const { travelAd } = useAdContext();
  const [isEditorLoading, setIsEditorLoading] = useState(true);
  const defaultValues = useMemo(() => {
    return travelAd
      ? {
          title: adData.title,
          about_my_services: adData.about_my_services,
          start_date: adData.start_date,
          expire_date: adData.expire_date,
          address: adData.address,
          city: adData.city,
          state: adData.state,
          country: adData.country,
        }
      : {
          title: adData.title,
          about_my_services: adData.about_my_services,
        };
  }, [adData]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdData((prevAd) => ({
      ...prevAd,
      [name]: value,
    }));
    setValue(name, value, { shouldValidate: true });
  };

  const handleEditorChange = (content, editor) => {
    setAdData((prevAd) => ({
      ...prevAd,
      about_my_services: content,
    }));
    setValue("about_my_services", content, { shouldValidate: true });
  };

  const onSubmit = (data) => {
    handleTabsChange(data, 1);
    setAdData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  useEffect(() => {
    // Simulate editor loading delay
    const timer = setTimeout(() => {
      setIsEditorLoading(false);
    }, 2000); // Adjust the delay as needed

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <main className="newAdModal__body__aboutAd">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginTop: "1rem" }}>
          <GreenInput
            label={t("masseur.myAd.title")}
            type="text"
            className="support__form__searchBox__search__searchInput"
            onChange={(e) => handleInputChange(e)}
            name="title"
            register={register("title")}
            error={errors.title?.message}
          />
          {errors.title && <p className="error">{errors.title.message}</p>}
        </div>
        {travelAd && (
          <>
            <div className="newAdModal__body__aboutAd__timeline">
              <div className="newAdModal__body__aboutAd__timeline__input">
                <GreenInput
                  label={t("masseur.myTravelAd.startDate")}
                  type="date"
                  className="support__form__searchBox__search__searchInput"
                  onChange={(e) => handleInputChange(e)}
                  name="start_date"
                  register={register("start_date")}
                  error={errors.start_date?.message}
                />
                {errors.start_date && (
                  <p className="error">{errors.start_date.message}</p>
                )}
              </div>
              <div className="newAdModal__body__aboutAd__timeline__input">
                <GreenInput
                  label={t("masseur.myTravelAd.expireDate")}
                  type="date"
                  className="support__form__searchBox__search__searchInput"
                  onChange={(e) => handleInputChange(e)}
                  name="expire_date"
                  register={register("expire_date")}
                  error={errors.expire_date?.message}
                />
                {errors.expire_date && (
                  <p className="error">{errors.expire_date.message}</p>
                )}
              </div>
            </div>
            <div className="newAdModal__body__aboutAd__timeline">
              <div className="newAdModal__body__aboutAd__timeline__input">
                <GreenInput
                  label={t("masseur.myTravelAd.address")}
                  type="text"
                  className="support__form__searchBox__search__searchInput"
                  onChange={(e) => handleInputChange(e)}
                  name="address"
                  register={register("address")}
                  error={errors.address?.message}
                />
                {errors.address && (
                  <p className="error">{errors.address.message}</p>
                )}
              </div>
              <div className="newAdModal__body__aboutAd__timeline__input">
                <GreenInput
                  label={t("masseur.myTravelAd.expireDate")}
                  type="text"
                  className="support__form__searchBox__search__searchInput"
                  onChange={(e) => handleInputChange(e)}
                  name="city"
                  register={register("city")}
                  error={errors.city?.message}
                />
                {errors.city && <p className="error">{errors.city.message}</p>}
              </div>
            </div>
            <div className="newAdModal__body__aboutAd__timeline">
              <div className="newAdModal__body__aboutAd__timeline__input">
                <GreenInput
                  label={t("masseur.myTravelAd.state")}
                  type="text"
                  className="support__form__searchBox__search__searchInput"
                  onChange={(e) => handleInputChange(e)}
                  name="state"
                  register={register("state")}
                  error={errors.state?.message}
                />
                {errors.state && (
                  <p className="error">{errors.state.message}</p>
                )}
              </div>
              <div className="newAdModal__body__aboutAd__timeline__input">
                <GreenInput
                  label={t("masseur.myTravelAd.country")}
                  type="text"
                  className="support__form__searchBox__search__searchInput"
                  onChange={(e) => handleInputChange(e)}
                  name="country"
                  register={register("country")}
                  error={errors.country?.message}
                />
                {errors.country && (
                  <p className="error">{errors.country.message}</p>
                )}
              </div>
            </div>
          </>
        )}
        {isEditorLoading ? (
          <Loading />
        ) : (
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey={"yn8hznqzfzs4rre9zarohcteunl838sugjqc5x68nci0ogpx"}
            initialValue={adData.about_my_services}
            init={{
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onChange={(e) => handleEditorChange(e.target.getContent())}
          />
        )}
        {errors.about_my_services && (
          <p className="error">{errors.about_my_services.message}</p>
        )}
        <div className="adVideoForm" style={{ marginTop: "3rem" }}>
          <div className="adVideoForm__buttons">
            <Button
              variant="contained"
              className="newAdModalButton"
              type="submit"
            >
              {t("masseur.myAd.next")}
            </Button>
          </div>
        </div>
      </form>
    </main>
  );
};

export default AboutAd;
