import React, { useState } from "react";
import videoApis from "../../../../../../apis/masseur/videoApis";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@mui/material";
import {
  AddOutlined as AddOutlinedIcon,
  InsertLink as InsertLinkIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const MAX_VIDEO_SIZE = 10 * 1024 * 1024;

const schema = yup.object().shape({
  url: yup.string().url("Enter a valid URL").nullable(),
  video_file: yup
    .mixed()
    .nullable()
    .test("fileSize", "File is too large", (value) => {
      return value == null || (value && value[0] && value[0].size <= 20485760);
    })
    .test("fileType", "Unsupported File Format", (value) => {
      return value && value[0]
        ? ["video/mp4", "video/x-msvideo", "video/quicktime"].includes(
            value[0].type
          )
        : true;
    }),
  description: yup.string().required("Description is required"),
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UploadVideo({ setVideos }) {
  const { t } = useTranslation();
  const [video, setVideo] = useState({
    url: null,
    video_file: null,
    description: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const { uploadVideo, isVideoLoading } = videoApis();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      url: "",
      video_file: null,
      description: "",
    },
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "video_file") {
      setVideo((prevVideo) => ({
        ...prevVideo,
        [name]: files[0],
        url: null,
      }));
      setValue(name, files[0]);
    } else if (name === "url") {
      setVideo((prevVideo) => ({
        ...prevVideo,
        [name]: value,
        video_file: null,
      }));
      setValue(name, value);
    } else {
      setVideo((prevVideo) => ({
        ...prevVideo,
        [name]: value,
      }));
      setValue(name, value);
    }
  };

  const onSubmit = async (data) => {
    // const formData = new FormData();
    // formData.append("url", data.url);
    // formData.append("video_file", data.video_file);
    // formData.append("description", data.description);

    const response = await uploadVideo(data);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setVideos((prevVideo) => [...prevVideo, response.data]);
      reset();
    }
  };

  const videoFile = watch("video_file");

  // if (isVideoLoading) return <Loading />;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="myvideo__uploadvideo__section"
    >
      <section className="myvideo__uploadvideo__section__greenbox">
        <div className="myvideo__uploadvideo__section__greenbox__container">
          <div className="myvideo__uploadvideo__section__greenbox__container__textfileldcontainer">
            <div className="myvideo__uploadvideo__section__greenbox__container__textfileldcontainer__icon">
              <InsertLinkIcon sx={{ color: "#3d3b3b" }} />
            </div>
            <div className="myvideo__uploadvideo__section__greenbox__container__textfileldcontainer__textfiled">
              <input
                placeholder="example.com/share-link"
                name="url"
                {...register("url")}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
      </section>
      {errors.url && <p className="error">{errors.url.message}</p>}
      <section className="myvideo__uploadvideo__section__borderbox">
        <span className="myvideo__uploadvideo__section__borderbox__title">
          {t("masseur.myVideo.uploadVideo")}
        </span>
        <div className="myvideo__uploadvideo__section__borderbox__dashedborderbox">
          <div className="myvideo__uploadvideo__section__borderbox__dashedborderbox__container">
            <div className="myvideo__uploadvideo__section__borderbox__dashedborderbox__container__icon">
              <AddOutlinedIcon />
            </div>
            <div className="myvideo__uploadvideo__section__borderbox__dashedborderbox__container__text">
              {t("masseur.myVideo.dropfile")}
            </div>
          </div>

          <span className="myvideo__uploadvideo__section__borderbox__dashedborderbox__buttonbox">
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              className="myvideo__uploadvideo__section__borderbox__dashedborderbox__buttonbox__button"
            >
              {t("masseur.myVideo.uploadfile")}
              <VisuallyHiddenInput
                type="file"
                name="video_file"
                accept="video/mp4, video/x-msvideo, video/quicktime"
                {...register("video_file")}
                // onChange={(e) => handleChange(e)}
              />
            </Button>
          </span>
          <input
            type="file"
            value={videoFile && videoFile.name}
            placeholder={videoFile && videoFile.name}
            readOnly
          />
          {errors.video_file && (
            <p className="error">{errors.video_file.message}</p>
          )}
        </div>
      </section>

      <section className="myvideo__uploadvideo__section__greenTextarea">
        <label>{t("masseur.myVideo.detail")}</label>
        <div className="myvideo__uploadvideo__section__greenTextarea__textBox">
          <textarea
            placeholder={t("masseur.myVideo.writeHere")}
            rows={3}
            name="description"
            {...register("description")}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </section>
      {errors.description && (
        <p className="error">{errors.description.message}</p>
      )}

      <section className="myvideo__uploadvideo__section__uploadbuttoncontainer">
        <Button
          className="myvideo__uploadvideo__section__uploadbuttoncontainer__button"
          typeof="file"
          type="submit"
        >
          {t("masseur.myVideo.upload")}
        </Button>
      </section>
    </form>
  );
}
