import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PackageApis from "../../../apis/masseur/PackageApis";

import CheckoutForm from "./CheckoutForm";
import { useParams } from "react-router-dom";
import Loading from "../../../components/general/Loading";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51OBr8lEbRFNIqwbrs9GjhbZUTjAJFxxfXG96ikyzLoTScJtr5htgfqgG9oLECDtyG6lknxh5SL94ebDd4FC8SqUA00flQqYI0e"
);

export default function StripePayment() {
  const { package_id, frequency_id, price } = useParams();
  const { getPaymentIntent, isPackagesLoading } = PackageApis();
  const [paymentSecret, setPaymentSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchPaymentIntent = async () => {
    const response = await getPaymentIntent(package_id, frequency_id);
    if (response.data) {
      setPaymentSecret(response.data.client_secret);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchPaymentIntent();
  }, []);

  if (isPackagesLoading) return <Loading />;
  if (errorMessage) return null;

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: paymentSecret,
  };

  return (
    <section className="paymentPage">
      {paymentSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            price={price}
            package_id={package_id}
            frequency_id={frequency_id}
          />
        </Elements>
      )}
    </section>
  );
}
