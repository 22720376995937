import React, { useState, useEffect } from "react";
import PersonalInformation from "../../profileSetup/PersonalInformation";
import ProfileSetup from "../../../../apis/masseur/ProfileSetup";
import { useToast } from "../../../../context/ToastContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function MasseurPersonalInformation() {
  const { masseur } = useSelector((state) => state.masseurAuth);
  const [info, setInfo] = useState({
    profile_image: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    country: "USA",
    phone: null,
    weight_unit: null,
    weight: null,
    height_unit: null,
    height: null,
    about: null,
    initial_profile_setup: masseur.setup_stage,
    height: null,
    images: [],
  });

  const { isLoading, sendProfileInfo } = ProfileSetup();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      if (name === "images") {
        setInfo({ ...info, images: [...info.images, files[0]] });
      } else {
        setInfo({ ...info, profile_image: files[0] });
      }
    } else {
      setInfo({ ...info, [name]: value });
    }
  };

  const handleSubmit = async () => {
    // const nullValue = Object.values(info).includes(null);
    // if (nullValue) {
    //   showToast({ message: "Please Fill the all data!", flag: "error" });
    //   return;
    // }

    await sendProfileInfo(masseur.id, info);
  };

  return (
    <PersonalInformation
      info={info}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      user="masseur"
    />
  );
}
