import React, { useState } from "react";
import BlueButton from "../../../components/Form/BlueButton";
import GreenInput from "../../../components/Form/GreenInput";
import GreenSelect from "../../../components/Form/GreenSelect";
import Greentextarea from "../../../components/Form/GreenTextarea";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Images from "./components/Images";
import GreenPhoneInput from "../../../components/Form/GreenPhoneInput";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const optionValues = [
  { label: "Select Country", value: "" },
  {
    label: "USA",
    value: "USA",
  },
  {
    label: "MX",
    value: "MX",
  },
];

export default function PersonalInformation({
  info,
  handleChange,
  handleSubmit,
  isLoading,
  user,
}) {
  const { t } = useTranslation();

  const [profileImage, setProfileImage] = useState(null);
  const [images, setImages] = useState([]);
  const handleImages = (e) => {
    const { name, files } = e.target;
    const reader = new FileReader();
    if (name === "profile_image") {
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    } else {
      reader.onload = (e) => {
        setImages([...images, e.target.result]);
      };
      reader.readAsDataURL(files[0]);
    }
    handleChange(e);
  };

  return (
    <div className="profileSetup">
      <div className="profileSetup__container">
        <header>
          <h3>{t("client.personalInformation.setUpProfile")}</h3>
        </header>
        <div className="profileSetup__container__body">
          <div className="profileSetup__container__body__left">
            <div className="profileSetup__container__body__left__profilePicture">
              <div className="profileSetup__container__body__left__profilePicture__profilePictureBox">
                <div>
                  <label for="image">
                    <PhotoCameraOutlinedIcon className="profileSetup__container__body__left__profilePicture__profilePictureBox__icon" />
                    <img
                      src={
                        profileImage
                          ? profileImage
                          : info.profile_image
                          ? info.profile_image
                          : require("../../../assets/Profile_Image.jpg")
                      }
                      alt=""
                    />
                  </label>
                </div>

                <input
                  type="file"
                  id="image"
                  name="profile_image"
                  onChange={(e) => handleImages(e)}
                />
              </div>
              <h3>{t("client.personalInformation.addProfilePicture")}</h3>
            </div>

            <GreenInput
              name="street"
              label={t("client.personalInformation.street")}
              placeholder={"Type here..."}
              type={"text"}
              handleChange={handleChange}
            />
            <div className="profileSetup__container__body__left__inputBox">
              <div>
                <GreenInput
                  name="city"
                  label={t("client.personalInformation.city")}
                  placeholder={t("client.personalInformation.typeHere")}
                  type={"text"}
                  handleChange={handleChange}
                />
              </div>
              <div>
                <GreenInput
                  name="state"
                  handleChange={handleChange}
                  label={t("client.personalInformation.state")}
                  placeholder={t("client.personalInformation.typeHere")}
                  type={"text"}
                />
              </div>
            </div>
            <div className="profileSetup__container__body__left__inputBox">
              <div>
                <GreenInput
                  label={t("client.personalInformation.zip")}
                  placeholder={t("client.personalInformation.typeHere")}
                  type={"number"}
                  handleChange={handleChange}
                  name="zip"
                />
              </div>
              <div>
                <GreenSelect
                  name="country"
                  label={t("client.personalInformation.country")}
                  options={optionValues}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="profileSetup__container__body__divider"></div>
          <div className="profileSetup__container__body__right">
            <GreenPhoneInput
              label={t("client.personalInformation.phoneNumber")}
              name="phone"
              handleChange={handleChange}
              value={info.phone}
              selectedCountry={info.country}
            />

            <Greentextarea
              label={t("client.personalInformation.personaDeatail")}
              placeholder={t("client.personalInformation.writeHere")}
              name="about"
              handleChange={handleChange}
            />
            {user == "masseur" && (
              <div className="profileSetup__container__body__right__profilePictureBox">
                <div>
                  <p> {t("client.personalInformation.image")}</p>
                  <div className="profileSetup__container__body__right__profilePictureBox__imagesBox">
                    <label
                      for="images"
                      className="profileSetup__container__body__right__profilePictureBox__imagesBox__label"
                    >
                      <AddOutlinedIcon className="profileSetup__container__body__right__profilePictureBox__imagesBox__label__icon" />
                    </label>
                    {images.length > 0 ? (
                      <Images images={images} />
                    ) : (
                      <Images images={info.images} />
                    )}
                  </div>
                </div>
                <input
                  id="images"
                  type="file"
                  name="images"
                  onChange={(e) => handleImages(e)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="profileSetup__container__nextButton">
          <BlueButton
            title={t("client.personalInformation.next")}
            loading={isLoading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
