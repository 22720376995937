import React, { useState, useEffect } from "react";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
import MasseurTabs from "./components/MasseurTabs";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MasseurProfile from "./components/MasseurProfile/MasseurProfile";
import BodyWrrxLoading from "../../../../components/general/BodyWerxLoading";
import MasseurAds from "../../../../apis/client/MasseurAds";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MasseurPortfolio() {
  const { t } = useTranslation();
  const { masseur_slug } = useParams();
  const { masseurProfile, isLoading } = MasseurAds();
  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchMasseurProfile = async () => {
    const response = await masseurProfile(masseur_slug);

    if (response.error) {
      setErrorMessage(response.errorMessage);
    } else {
      setProfile(response.data);
    }
  };
  useEffect(() => {
    fetchMasseurProfile();
  }, []);

  if (isLoading) return <BodyWrrxLoading />;
  if (errorMessage) return null;

  return (
    <ClientPage>
      {profile ? (
        <main className="clientPorfile">
          <MasseurProfile profile={profile} />
          <section className="clientPorfile__clientPortfolio">
            <MasseurTabs profile={profile} />
          </section>
          <section className="clientPorfile__messageIcon">
            <QuestionAnswerIcon />
          </section>
        </main>
      ) : (
        <h1 className="noVideo__title">{t("client.title.dataloaded")}</h1>
      )}
    </ClientPage>
  );
}
