import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileApis from "../../../../../apis/masseur/ProfileApis";
import Loading from "../../../../../components/general/Loading";
import { useSelector } from "react-redux/es/hooks/useSelector";
import TrialPayamentView from "./TrialPaymentView";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Box, Modal } from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Done as DoneIcon,
  TaskAlt as TaskAltIcon,
} from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none !important",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  width: 600,
};

export default function ViewPayment() {
  const { t } = useTranslation();
  const { masseur } = useSelector((state) => state.masseurAuth);

  const { isProfileLoading, getCurrentSubscription } = ProfileApis();
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    fetchCurrentSubscription();
    if (errorMessage) return null;
  };
  const handleClose = () => setOpen(false);

  const fetchCurrentSubscription = async () => {
    const response = await getCurrentSubscription();
    if (response.data) {
      setCurrentSubscription(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  return (
    <main>
      <header
        className="masseur__profilesetting__section__box__container"
        onClick={handleOpen}
      >
        <h4 className="masseur__profilesetting__section__box__container__title">
          {t("masseur.myProfile.viewPaymentLevel")}
        </h4>
        <div className="masseur__profilesetting__section__box__container__icon">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      </header>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          {isProfileLoading || currentSubscription == null ? (
            <Loading />
          ) : (
            <div>
              <header className="modal__container">
                <div className="modal__container__icon" onClick={handleClose}>
                  <ArrowBackIosIcon />
                </div>
              </header>
              <aside className="plan">
                <DoneIcon fontSize="small" />
                <h6> {t("masseur.viewPayement.currentPlan")}</h6>
              </aside>
              {!masseur.subscribed && masseur.account_trial ? (
                <TrialPayamentView
                  trial={currentSubscription.trial}
                  levelPackage={currentSubscription.package}
                />
              ) : masseur.subscribed ? (
                <>
                  <aside className="payment__plan">
                    <div className="payment__plan__detail">
                      <h2 className="payment__plan__detail__title">
                        {currentSubscription.package.package_type}
                      </h2>
                      <p className="payment__plan__detail__paragraph">
                        {t("masseur.viewPayement.expirePackage", {
                          date: format(
                            currentSubscription.expire_date,
                            "MMMM do, yyyy"
                          ),
                        })}
                      </p>
                    </div>
                    <div className="payment__plan__container">
                      <h2 className="payment__plan__title">
                        ${currentSubscription.amount}{" "}
                      </h2>
                    </div>
                  </aside>
                  <aside className="plan__list">
                    <TaskAltIcon sx={{ color: "#0AB361 " }} />
                    <h4> {t("masseur.viewPayement.billTech")} </h4>
                  </aside>
                  <aside className="plan__list">
                    <TaskAltIcon sx={{ color: "#0AB361 " }} />
                    <h4>{t("masseur.viewPayement.automatice")}</h4>
                  </aside>
                  <aside className="plan__list">
                    <TaskAltIcon sx={{ color: "#0AB361" }} />
                    <h4>{t("masseur.viewPayement.onceAccount")}</h4>
                  </aside>
                  <aside className="plan__list">
                    <TaskAltIcon sx={{ color: "#0AB361 " }} />
                    <h4>{t("masseur.viewPayement.expenceReport")}</h4>
                  </aside>
                  <aside className="plan__list">
                    <TaskAltIcon sx={{ color: "#0AB361" }} />
                    <h4>{t("masseur.viewPayement.cashwallets")}</h4>
                  </aside>
                </>
              ) : (
                <h2>No Subscribed!</h2>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </main>
  );
}
