import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IconButton } from "@mui/material";
import "swiper/css/autoplay";
import { useNavigate } from "react-router-dom";
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  LocationOn as LocationOnIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  WorkspacePremiumOutlined as WorkspacePremiumOutlinedIcon,
  NavigateBefore as NavigateBeforeIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import FavouriteApis from "../../../../../apis/client/favouriteApis";
import { useSelector } from "react-redux";
import { Rating } from "@mui/material";

const Card = ({ ad, favourite, setRefetch = null }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { client } = useSelector((state) => state.clientAuth);
  const { addFavourite, isFavouriteLoading, deleteFavourite } = FavouriteApis();
  const [disable, setDisabled] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(favourite);

  const breakpoints = {
    1000: {
      slidesPerView: 2,
    },
  };

  const getSlidesPerView = () => {
    return Object.keys(breakpoints)
      .sort((a, b) => b - a)
      .find((breakpoint) => screenWidth >= breakpoint);
  };

  const addFavouriteHandler = async (ad) => {
    await addFavourite({
      ad_id: ad.id,
      masseur_id: ad.masseur.id,
      client_id: client.id,
    });
  };

  const addDeleteHandler = async (ad) => {
    await deleteFavourite(ad.id);
    setRefetch(true);
  };

  const slidesPerView = breakpoints[getSlidesPerView()]?.slidesPerView || 1;

  useEffect(() => {
    setDisabled(ad?.ad_photos.length <= 2);
  }, [ad]);

  return (
    <section className="landingPage__container__cardsContainer__cards__card">
      <figure className="landingPage__container__cardsContainer__cards__card__imageBox">
        <img
          src={
            ad?.profile_image ||
            require("../../../../../assets/Profile_Image.jpg")
          }
          alt=""
          className="landingPage__container__cardsContainer__cards__card__imageBox__image"
        />
        <figcaption className="landingPage__container__cardsContainer__cards__card__imageBox__details">
          <div className="landingPage__container__cardsContainer__cards__card__imageBox__details__container">
            <h4
              onClick={() =>
                navigate(`/masseurs/${ad.masseur.slug}/ads/${ad.slug}`)
              }
            >
              {ad?.masseur?.screen_name}
            </h4>
            {client != null && (
              <>
                {active === true ? (
                  <FavoriteIcon
                    sx={{
                      cursor: "pointer",
                      color: "#193D66 !important",
                    }}
                    onClick={() => addDeleteHandler(ad)}
                  />
                ) : (
                  <FavoriteBorderIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => addFavouriteHandler(ad)}
                  />
                )}
              </>
            )}
          </div>
          <p>{ad?.title}</p>

          <aside className="landingPage__container__cardsContainer__cards__card__imageBox__details__rating">
            <Rating
              name="read-only"
              value={ad.masseur.review_ratings}
              readOnly
            />
          </aside>
          <h5 className="landingPage__container__cardsContainer__cards__card__imageBox__details__bio">
            {ad.masseur.weight} {ad.masseur.weight_unit} | {ad.masseur.height}{" "}
            {ad.masseur.height_unit} | {ad.masseur.age}{" "}
            {t("client.landingPage.year")}
          </h5>
        </figcaption>
      </figure>
      <section className="landingPage__container__cardsContainer__cards__card__services">
        <h5>{t("client.landingPage.service")}</h5>
        <aside className="landingPage__container__cardsContainer__cards__card__services__offers">
          {ad?.services.map((service) => (
            <span className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
              {service?.name}
            </span>
          ))}
          {ad?.massage_types.map((massage) => (
            <span className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
              {massage?.name}
            </span>
          ))}
        </aside>
      </section>
      {/* <section className="landingPage__container__cardsContainer__cards__card__location">
        <LocationOnIcon className="landingPage__container__cardsContainer__cards__card__location__icon" />
        <h5>{ad.ad_address}</h5>
      </section> */}

      <figure className="myAds__body__container2__adsContainer2__adCard__images">
        <header className="myAds__body__container2__adsContainer2__adCard__images__header">
          <h5>Images</h5>
          <section className="myAds__body__container2__adsContainer2__adCard__images__header__icons">
            <IconButton disabled={disable} id={`prevButton-${ad?.id}`}>
              <NavigateBeforeIcon
                className="myAds__body__container2__adsContainer2__adCard__images__header__icons__icon NavigateBeforeIcon "
                sx={{
                  backgroundColor: disable ? "gray !important" : "",
                }}
              />
            </IconButton>
            <IconButton disabled={disable} id={`nextButton-${ad?.id}`}>
              <KeyboardArrowRightIcon
                className="myAds__body__container2__adsContainer2__adCard__images__header__icons__icon"
                sx={{
                  backgroundColor: disable ? "gray !important" : "",
                }}
              />
            </IconButton>
          </section>
        </header>
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={10}
          slidesPerView={slidesPerView}
          className="myAds__body__container2__adsContainer2__adCard__images__imageBox"
          navigation={{
            prevEl: `#prevButton-${ad?.id}`,
            nextEl: `#nextButton-${ad?.id}`,
          }}
          loop={true}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          {ad?.ad_photos.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image?.image_url}
                alt=""
                style={{ width: "100%", height: "130px" }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </figure>
      <footer className="landingPage__container__cardsContainer__cards__card__descBox">
        <section className="landingPage__container__cardsContainer__cards__card__descBox__desc">
          <article className="landingPage__container__cardsContainer__cards__card__descBox__desc__review">
            <ThumbUpOutlinedIcon className="landingPage__container__cardsContainer__cards__card__descBox__desc__review__icon" />
            <h5>81 {t("client.landingPage.review")}</h5>
          </article>
          <article className="landingPage__container__cardsContainer__cards__card__descBox__desc__services">
            <WorkspacePremiumOutlinedIcon className="landingPage__container__cardsContainer__cards__card__descBox__desc__services__icon" />
            <h5>87 {t("client.landingPage.serviceCompleted")}</h5>
          </article>
        </section>
        <section className="landingPage__container__cardsContainer__cards__card__descBox__price">
          <p>{t("client.landingPage.starting")}</p>
          <h5>${ad?.starting_price}</h5>
        </section>
      </footer>
    </section>
  );
};

export default Card;
