import React from "react";
import { Outlet } from "react-router-dom";
import MasseurNavbar from "../Navbar/MasseurNavbar";

const MasseurPage = () => {
  return (
    <div className="masseurPage">
      <MasseurNavbar />
      <Outlet />
    </div>
  );
};

export default MasseurPage;
