import React, { useState } from "react";
import MasseurAuth from "../../../apis/MasseurAuth";
import SignupForm from "./SignupForm";

export default function MasseurSignup() {
  const { masseurSignup } = MasseurAuth();

  const [masseur, setMasseur] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    dob: null,
    screen_name: null,
  });

  const onSubmit = async (data) => {
    await masseurSignup(data);
  };

  return (
    <SignupForm
      user={masseur}
      setUser={setMasseur}
      onSubmit={onSubmit}
    />
  );
}
