import React, { useState, useEffect, useMemo } from "react";
import BlueButton from "../../../../../components/Form/BlueButton";
import ProfileSetup from "../../../../../apis/masseur/ProfileSetup";
import ProfileApis from "../../../../../apis/masseur/ProfileApis";
import Loading from "../../../../../components/general/Loading";
import { useTranslation } from "react-i18next";
import { Box, Modal } from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  PhotoCameraOutlined as PhotoCameraOutlinedIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  boxShadow: 24,
  p: 4,
  bgcolor: "white",
  height: "auto",
  borderRadius: "10px",
  width: "75%",
};
export default function EditService({ setProfile, profile }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    fetchServicesAndMassageType();
  };
  const handleClose = () => setOpen(false);

  const [allTypes, setAllTypes] = useState({
    all: false,
    all_massage_type: false,
  });
  const [selectedServicesAndMassageType, setSelectedServicesAndMassageType] =
    useState({
      service_ids: profile.service_ids,
      massage_type_ids: profile.massage_type_ids,
    });

  const [servicesAndMassageTypes, setServiceAndMassageTypes] = useState({
    massage_types: [],
    services: [],
  });
  const [submitLoader, setSubmitLoader] = useState(false);

  const { isLoading, getServicesAndMassageTypes } = ProfileSetup();
  const { sendServicesAndMassageTypes } = ProfileApis();

  const fetchServicesAndMassageType = async () => {
    const response = await getServicesAndMassageTypes();
    setServiceAndMassageTypes(response);
  };

  const handleSubmit = async () => {
    setSubmitLoader(true);
    const response = await sendServicesAndMassageTypes(
      selectedServicesAndMassageType
    );
    if (response) {
      setProfile(response);
    }
    handleClose();
    setSubmitLoader(false);
  };

  const handleChange = (e, type) => {
    const value = Number(e.target.value);

    const service_ids = selectedServicesAndMassageType.service_ids;
    const massage_types_ids = selectedServicesAndMassageType.massage_type_ids;

    if (type == "all") {
      !allTypes.all
        ? setSelectedServicesAndMassageType({
            service_ids: servicesAndMassageTypes.services.map(
              (service) => service.id
            ),
            massage_type_ids: servicesAndMassageTypes.massage_types.map(
              (massage_type) => massage_type.id
            ),
          })
        : setSelectedServicesAndMassageType({
            service_ids: [],
            massage_type_ids: [],
          });
    } else if (type == "all_massage_type") {
      !allTypes.all_massage_type
        ? setSelectedServicesAndMassageType({
            ...selectedServicesAndMassageType,
            massage_type_ids: servicesAndMassageTypes.massage_types.map(
              (massage_type) => massage_type.id
            ),
          })
        : setSelectedServicesAndMassageType({
            ...selectedServicesAndMassageType,
            massage_type_ids: [],
          });
    } else if (type == "service") {
      const service_flag = service_ids.includes(value);

      if (!service_flag) {
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          service_ids: [...service_ids, value],
        });
      } else {
        const remainingValues =
          selectedServicesAndMassageType.service_ids.filter(
            (item) => item != value
          );
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          service_ids: remainingValues,
        });
      }
    } else if (type == "massage_type") {
      const massage_flag = massage_types_ids.includes(value);
      if (!massage_flag) {
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          massage_type_ids: [...massage_types_ids, value],
        });
      } else {
        const remainingValues =
          selectedServicesAndMassageType.massage_type_ids.filter(
            (item) => item != value
          );
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          massage_type_ids: remainingValues,
        });
      }
    }
  };

  const checkedAllFlag = () => {
    const services_flag =
      selectedServicesAndMassageType.service_ids?.length ==
      servicesAndMassageTypes.services.length;
    const massage_flag =
      selectedServicesAndMassageType.massage_type_ids?.length ==
      servicesAndMassageTypes.massage_types?.length;

    if (
      selectedServicesAndMassageType.service_ids?.length > 0 &&
      services_flag &&
      massage_flag
    ) {
      setAllTypes({
        all: true,
        all_massage_type: true,
      });
    } else if (
      selectedServicesAndMassageType.massage_type_ids?.length > 0 &&
      massage_flag
    ) {
      setAllTypes({
        all: false,
        all_massage_type: true,
      });
    } else {
      setAllTypes({
        all: false,
        all_massage_type: false,
      });
    }
  };

  useEffect(() => {
    checkedAllFlag();
  }, [selectedServicesAndMassageType]);

  return (
    <main>
      <section
        className="masseur__profilesetting__section__box__container"
        onClick={handleOpen}
      >
        <h4 className="masseur__profilesetting__section__box__container__title">
          {t("masseur.myProfile.editService")}
        </h4>
        <div className="masseur__profilesetting__section__box__container__icon">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          {isLoading ? (
            <Loading />
          ) : (
            <section className="editService__modal">
              <header className="modal__container" style={{ width: "100%" }}>
                <div className="modal__container__icon" onClick={handleClose}>
                  <ArrowBackIosIcon />
                </div>

                <h3 className="editService__modal__title">
                  {t("masseur.myProfile.service")}
                </h3>
              </header>
              <section className="servicesOffer__container__body">
                <figure className="servicesOffer__container__body__left">
                  <img
                    src={require("../../../../../assets/Services_offer.png")}
                    alt=""
                  />
                </figure>

                <aside className="servicesOffer__container__body__right">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <section className="servicesOffer__container__body__right__radioInput">
                        <input
                          name="all"
                          type="radio"
                          onClick={(e) => handleChange(e, "all")}
                          // value="all"
                          checked={allTypes.all}
                        />
                        <label>Select All</label>
                      </section>
                      {servicesAndMassageTypes.services.map((service) => {
                        return (
                          <div className="greenRadioInput">
                            <input
                              name={`service_${service.id}`}
                              type="radio"
                              checked={selectedServicesAndMassageType.service_ids.includes(
                                service.id
                              )}
                              onClick={(e) => handleChange(e, "service")}
                              value={service.id}
                            />
                            <label>{service.name}</label>
                          </div>
                        );
                      })}
                      {/* <GreenRadioInput label={"Manscaping"} />
                <GreenRadioInput label={"Personal Training"} />
                {}
                <GreenRadioInput label={"Massage Therapy"} /> */}
                      {servicesAndMassageTypes.massage_types.length > 0 && (
                        <>
                          <div className="greenRadioInput">
                            <input
                              name="all_massage_type"
                              type="radio"
                              onClick={(e) =>
                                handleChange(e, "all_massage_type")
                              }
                              // value="all_massage_type"
                              checked={allTypes.all_massage_type}
                            />
                            <label>Massage Therapy</label>
                          </div>

                          {servicesAndMassageTypes.massage_types.map(
                            (massage_type) => {
                              return (
                                <div className="servicesOffer__container__body__right__radioInput servicesOffer__container__body__right__radioInput2">
                                  <input
                                    name={`massage_${massage_type.id}`}
                                    type="radio"
                                    checked={selectedServicesAndMassageType.massage_type_ids.includes(
                                      massage_type.id
                                    )}
                                    onClick={(e) =>
                                      handleChange(e, "massage_type")
                                    }
                                    value={massage_type.id}
                                  />
                                  <label>{massage_type.name}</label>
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                    </>
                  )}
                </aside>
              </section>
              <section className="servicesOffer__container__nextButton">
                <BlueButton
                  loading={submitLoader}
                  variant="contained"
                  className="passwordChange__container__saveButton"
                  onClick={handleSubmit}
                  title={t("masseur.myProfile.saveChanges")}
                />
              </section>
            </section>
          )}
        </Box>
      </Modal>
    </main>
  );
}
