import React, { useState } from "react";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import { onSuccess, onError } from "./request";

export default function ServiceApis() {
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const { showToast } = useToast();

  const getServices = async () => {
    setIsServiceLoading(true);

    return await axios
      .get(`${SERVER_API}/masseurs/services/`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsServiceLoading(false);
        onSuccess(response, showToast, "Fetch Data!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsServiceLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  };

  return {
    getServices,
    isServiceLoading,
  };
}
