import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import { onSuccess, onError } from "./request";
import { store } from "../../redux/store";

export default function PhotoApis() {
  const [isPhotoLoading, setIsPhotoLoading] = useState(true);
  const { showToast } = useToast();

  async function getPhotos() {
    setIsPhotoLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/photos`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsPhotoLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsPhotoLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }
  async function addPhoto(photo) {
    setIsPhotoLoading(true);
    const formData = new FormData();
    formData.append("image", photo);

    return await axios
      .post(`${SERVER_API}/masseurs/photos`, formData, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsPhotoLoading(false);
        onSuccess(response, showToast, "Updated masseur address!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsPhotoLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function deletePhoto(id) {
    setIsPhotoLoading(true);
    return await axios
      .delete(`${SERVER_API}/masseurs/photos/${id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Photo delete successfully");
        setIsPhotoLoading(false);
        return {
          data: true,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsPhotoLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getPhotos,
    addPhoto,
    deletePhoto,
    isPhotoLoading,
  };
}
