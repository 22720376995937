import React, { useEffect, useState } from "react";
import PhotoApis from "../../apis/masseur/photoApis";
import Loading from "../general/Loading";
import { Button } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useTranslation } from "react-i18next";

export default function AdPhotos({ handleTabsChange, adData, setAdData }) {
  const [photos, setPhotos] = useState([]);
  const { isPhotoLoading, getPhotos } = PhotoApis();
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageId, setImageId] = useState([]);
  const { t } = useTranslation();

  const fetchPhotos = async () => {
    const response = await getPhotos();
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setPhotos(response.data);
    }
  };

  const handleImageId = (id) => {
    const isSelected = adData.photo_ids.includes(id);
    const images = isSelected
      ? adData.photo_ids.filter((item) => item !== id)
      : [...adData.photo_ids, id];
    setAdData((prevState) => ({ ...prevState, photo_ids: images }));
    console.log(adData);
    console.log(images);
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  if (isPhotoLoading) return <Loading />;
  if (errorMessage) return null;
  return (
    <div className="newAdModal__body__pictures">
      <header>
        <h3>{t("masseur.myAd.SelectPictures")}</h3>
      </header>

      <div className="myPhoto__container__body">
        {photos.map((item) => (
          <div className="myPhoto__container__body__image">
            <img
              key={item.id}
              src={item.image_url}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => handleImageId(item.id)}
            />
            {adData.photo_ids.includes(item.id) && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "green",
                  padding: ".8rem",
                }}
              >
                <DoneAllIcon />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="adVideoForm">
        <div className="adVideoForm__buttons">
          <Button
            variant="contained"
            className="newAdModalButton"
            onClick={(e) => handleTabsChange(e, 1)}
          >
            {t("masseur.myAd.back")}
          </Button>
          <Button
            variant="contained"
            className="newAdModalButton"
            onClick={(e) => handleTabsChange(e, 3)}
          >
            {t("masseur.myAd.next")}
          </Button>
        </div>
      </div>
    </div>
  );
}
