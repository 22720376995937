import React, { useEffect, useState } from "react";
import Review from "./Review/index";
import TotalReview from "./TotalReview/index";
import ReviewApis from "../../../../apis/masseur/ReviewApis";
import Loading from "../../../../components/general/Loading";
export default function ClientReview() {
  const { isReviewLoading, getReviews, deleteReview, updateReview } =
    ReviewApis();
  const [reviewData, setReviewData] = useState({
    reviews: [],
    total_reviews: 0,
    average_rating: 0,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchReviews = async () => {
    const response = await getReviews();
    if (response.data) {
      setReviewData(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };
  const handleApproved = async (e, id) => {
    const response = await updateReview({
      id: id,
      masseur_approved: e.target.checked,
    });
    if (response.data) {
      setReviewData(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteReview(id);
    if (response.data) {
      setReviewData(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  if (errorMessage) return null;

  return (
    <main className="clientreview__container">
      {isReviewLoading ? (
        <div className="circularLoading">
          <Loading />
        </div>
      ) : (
        <>
          <div className="clientreview__container__client">
            <Review
              reviews={reviewData.reviews}
              handleApproved={handleApproved}
              handleDelete={handleDelete}
            />
          </div>
          <div className="clientreview__container__totalrating">
            <TotalReview
              totalReviews={reviewData.total_reviews}
              rating={reviewData.average_rating}
            />
          </div>
        </>
      )}
    </main>
  );
}
