import React from "react";
import { Rating, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const labels = {
  0.5: "Useless",
  1.0: "Useless+",
  1.5: "Poor",
  2.0: "Poor+",
  2.5: "Ok",
  3.0: "Ok+",
  3.5: "Good",
  4.0: "Good+",
  4.5: "Excellent",
  5.0: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function TotalReview({ totalReviews, rating }) {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(4);
  const [hover, setHover] = React.useState(-1);

  return (
    <section className="clientreview__container__totalrating__section">
      <div className="clientreview__container__totalrating__section__title">
        <h3>{t("masseur.clientReview.totalReview")}</h3>
      </div>
      <div className="clientreview__container__totalrating__section__box">
        <div className="clientreview__container__totalrating__section__box__container">
          <div className="clientreview__container__totalrating__section__box__container__title">
            <h1>{totalReviews}</h1>
          </div>
          {/* <div className="clientreview__container__totalrating__section__box__container__trending">
            <div className="clientreview__container__totalrating__section__box__container__trending__percentage">
              <span>21%</span>
            </div>
            <div className="clientreview__container__totalrating__section__box__container__trending__icon">
              <TrendingUpIcon fontSize="medium" />
            </div>
          </div> */}
        </div>
        <div className="clientreview__container__totalrating__section__box__paragraph">
          <p>{t("masseur.clientReview.description")}</p>
        </div>
      </div>
      <div className="clientreview__container__totalrating__section__averageratingtitle">
        <h3>{t("masseur.clientReview.averageRating")}</h3>
      </div>
      <div className="clientreview__container__totalrating__section__box">
        <div className="clientreview__container__totalrating__section__averageratingcontainer">
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="clientreview__container__totalrating__section__averageratingcontainer__title">
              <h1>{rating}</h1>
            </div>
            <div className="clientreview__container__totalrating__section__averageratingcontainer__icon">
              <Rating name="read-only" value={rating} readOnly />
            </div>
          </Box>
        </div>
        <div className="clientreview__container__totalrating__section__box__paragraph">
          <p>{t("masseur.clientReview.averageRatingDescription")}</p>
        </div>
      </div>
      {/* <div className="clientreview__container__totalrating__section__averageratingtitle">
        <h3>{t("masseur.clientReview.ratingStat")}</h3>
      </div> */}
      {/* <div className="clientreview__container__totalrating__section__box">
        <div className="clientreview__container__totalrating__section__ratingstat">
          <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint">
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__icon">
              <StarIcon sx={{ color: "#cbced3" }} />
            </div>
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__number">
              <span>5</span>
            </div>
          </div>
          <div className="clientreview__container__totalrating__section__ratingstat__border"></div>
          <div className="clientreview__container__totalrating__section__ratingstat__number">
            <span>2.0k</span>
          </div>
        </div>
        <div className="clientreview__container__totalrating__section__ratingstat">
          <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint">
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__icon">
              <StarIcon sx={{ color: "#cbced3" }} />
            </div>
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__number">
              <span>4</span>
            </div>
          </div>
          <div className="clientreview__container__totalrating__section__ratingstat__border__one"></div>
          <div className="clientreview__container__totalrating__section__ratingstat__number">
            <span>1.0k</span>
          </div>
        </div>
        <div className="clientreview__container__totalrating__section__ratingstat">
          <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint">
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__icon">
              <StarIcon sx={{ color: "#cbced3" }} />
            </div>
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__number">
              <span>3</span>
            </div>
          </div>
          <div className="clientreview__container__totalrating__section__ratingstat__border__two"></div>
          <div className="clientreview__container__totalrating__section__ratingstat__number">
            <span>500</span>
          </div>
        </div>
        <div className="clientreview__container__totalrating__section__ratingstat">
          <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint">
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__icon">
              <StarIcon sx={{ color: "#cbced3" }} />
            </div>
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__number">
              <span>2</span>
            </div>
          </div>
          <div className="clientreview__container__totalrating__section__ratingstat__border__three"></div>
          <div className="clientreview__container__totalrating__section__ratingstat__number">
            <span>200</span>
          </div>
        </div>
        <div className="clientreview__container__totalrating__section__ratingstat">
          <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint">
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__icon">
              <StarIcon sx={{ color: "#cbced3" }} />
            </div>
            <div className="clientreview__container__totalrating__section__ratingstat__ratingpoint__number">
              <span>1</span>
            </div>
          </div>
          <div className="clientreview__container__totalrating__section__ratingstat__border__four"></div>
          <div className="clientreview__container__totalrating__section__ratingstat__number">
            <span>{t("masseur.clientReview.ok")}</span>
          </div>
        </div>
      </div> */}
    </section>
  );
}
