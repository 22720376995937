import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";
import { objectToQueryString } from "./request";
import { clientLogoutSuccess } from "../../redux/slices/auth/client";
import { store } from "../../redux/store";
import { useNavigate } from "react-router-dom";

export default function HomeApis() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getAds(search, page) {
    const object = objectToQueryString(search);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/home?locale=${language.id}&${object}&page=${page}`
        )
        .then((response) => response)
        .then((response) => {
          onSuccess(response, showToast, "Ads are loaded!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          onError(error, showToast);
          return {
            data: null,
            errorMessage: error.response.data.message,
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  }

  async function masseurAd(masseur_slug, ad_slug) {
    setIsLoading(true);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/home/masseur_add${masseur_slug}?locale=${language.id}&ad_slug=${ad_slug}`
        )
        .then((response) => response)
        .then((response) => {
          setIsLoading(false);
          onSuccess(response, showToast, "Ads are loaded!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          setIsLoading(false);
          onError(error, showToast);
          return {
            data: null,
            errorMessage: error.response.data.message,
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  }

  async function getTopRatedMasseursdAds() {
    setIsLoading(true);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/home/top_rated_masseurs_ads?locale=${language.id}`
        )
        .then((response) => response)
        .then((response) => {
          setIsLoading(false);
          onSuccess(response, showToast, "Ads are loaded!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          setIsLoading(false);
          onError(error, showToast);
          return {
            data: null,
            errorMessage: error.response.data.message,
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  }
  const getServicesAndMassageTypes = async () => {
    setIsLoading(true);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/home/services_and_massage_types?locale=${language.id}`
        )
        .then((response) => response)
        .then((response) => {
          setIsLoading(false);
          onSuccess(response, showToast, "Data fetched!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          onError(error, showToast);
          setIsLoading(false);
          return {
            data: null,
            errorMessage: error.response.data?.message
              ? error.response.data.message
              : "Service Error",
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  };

  const clientSignOut = async () => {
    setIsLoading(true);
    await axios
      .delete(`${SERVER_API}/clients/logout`, {
        headers: {
          Authorization: Cookies.get("client-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Logout Successfully!");
        store.dispatch(clientLogoutSuccess());
        navigate("/signin");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        onError(error, showToast);
      });
  };

  return {
    clientSignOut,
    getAds,
    getServicesAndMassageTypes,
    getTopRatedMasseursdAds,
    isLoading,
    masseurAd,
  };
}
