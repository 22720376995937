import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import axios from "axios";
import Cookies from "js-cookie";
import { onError, onSuccess } from "./request";
import { useToast } from "../../context/ToastContext";

export default function VideoApis() {
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const { showToast } = useToast();

  async function getVideos() {
    setIsVideoLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/videos`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsVideoLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsVideoLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function uploadVideo(video) {
    const formData = new FormData();

    for (const property in video) {
      if (property == "video_file" && video[property]) {
        formData.append(`${property}`, video[property][0]);
      } else {
        formData.append(`video[${property}]`, video[property]);
      }
    }

    return await axios
      .post(`${SERVER_API}/masseurs/videos`, formData, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => {
        onSuccess(response, showToast, "Video Uploaded!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }
  async function deleteUploadVideo(id) {
    try {
      setIsVideoLoading(true);

      const response = await axios.delete(
        `${SERVER_API}/masseurs/video/${id}`,
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      );

      setIsVideoLoading(false);
      showToast({ message: response.data.message, flag: "success" });
    } catch (error) {
      setIsVideoLoading(false);
      showToast({
        message: error.response?.data?.message || "An error occurred",
        flag: "error",
      });
    }
  }

  return { getVideos, isVideoLoading, uploadVideo, deleteUploadVideo };
}
