import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { store } from "../../redux/store";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import { onSuccess, onError, formDataToObject } from "./request";
import { masseurData } from "../../redux/slices/auth/masseur";
import { useSelector } from "react-redux";

export default function ProfileSetup() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { language } = useSelector((state) => state.i18n);

  const sendProfileInfo = async (id, masseur) => {
    setIsLoading(true);
    const formData = new FormData();

    for (const property in masseur) {
      if (property == "profile_image") {
        formData.append(property, masseur[property]);
      }
      if (property == "images") {
        masseur.images.forEach((file, index) => {
          formData.append(`images[${index}]`, file);
        });
      } else {
        formData.append(`masseur[${property}]`, masseur[property]);
      }
    }

    const response = await axios
      .put(
        `${SERVER_API}/masseurs/profile_setup/${id}?locale=${language.id}`,
        formData,
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        store.dispatch(
          masseurData({
            masseur: response.data,
          })
        );
        onSuccess(
          response,
          showToast,
          "Profile information saved successfully!"
        );
        navigate(`/masseur/profileSetup/${response.data.setup_stage}`);
      })
      .catch((error) => {
        onError(error, showToast);
      });
    setIsLoading(false);
    return response;
  };

  const phoneVerification = async (code) => {
    setIsLoading(true);
    return await axios
      .put(
        `${SERVER_API}/masseurs/profile_setup/phone_verification`,
        { code: code },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        if (response.data.phone_verified) {
          store.dispatch(
            masseurData({
              masseur: response.data,
            })
          );
          onSuccess(response, showToast, "Mobile has been verified");
          navigate(`/masseur/profileSetup/${response.data.setup_stage}`);
        } else {
          showToast({ message: "Please enter valid OTP", flag: "error" });
        }
      })
      .catch((error) => {
        onError(error, showToast);
        setIsLoading(false);
      });
  };

  const getServicesAndMassageTypes = async () => {
    setIsLoading(true);

    return await axios
      .get(
        `${SERVER_API}/masseurs/profile_setup/services_and_massage_types?locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return response.data;
      })
      .catch((error) => {
        onError(error, showToast);
        setIsLoading(false);
      });
  };

  const sendServicesAndMassageTypes = async (params) => {
    setIsLoading(true);
    const response = await axios
      .put(
        `${SERVER_API}/masseurs/profile_setup/massage_and_services?locale=${language.id}`,
        { ...params },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Added Services and Massage Types");
        navigate(`/masseur/profileSetup/${response.data.setup_stage}`);
      })
      .catch((error) => {
        onError(error, showToast);
      });
  };

  const createTrialAccount = async (params = { active: true }) => {
    const response = await axios
      .put(
        `${SERVER_API}/masseurs/profile_setup/account_trial?locale=${language.id}`,
        { ...params },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Account Trial has been created");
        if (
          response.data.active &&
          response.data.initial_profile_setup == "completed"
        ) {
          navigate("/masseur/profile");
        } else {
          navigate(`/masseur/profileSetup/${response.data.setup_stage}`);
        }
      })
      .catch((error) => {
        onError(error, showToast);
      });
  };

  const resendVerification = async () => {
    debugger;
    setIsLoading(true);
    return await axios
      .get(
        `${SERVER_API}/masseurs/profile_setup/resend_verification?locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        onSuccess(response, showToast, "OTP code is sent!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  };
  return {
    sendProfileInfo,
    phoneVerification,
    getServicesAndMassageTypes,
    sendServicesAndMassageTypes,
    createTrialAccount,
    resendVerification,
    isLoading,
  };
}
