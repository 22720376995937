import React, { useState } from "react";
import GreenInput from "../../../../../../components/Form/GreenInput";
import ServiceHourApis from "../../../../../../apis/masseur/serviceHourApis";
import BlueButton from "../../../../../../components/Form/BlueButton";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Modal,
} from "@mui/material";
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";

const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

const schema = yup.object().shape({
  id: yup.number().default(),
  service_type: yup.boolean().default(false),
  day: yup.string().when("service_type", {
    is: false,
    then: (schema) => schema.required("Day is required"),
  }),
  from_time: yup
    .string()
    .matches(timeRegex, "Time must be in HH:MM format")
    .required("Time is required"),
  to_time: yup
    .string()
    .matches(timeRegex, "Time must be in HH:MM format")
    .required("Time is required"),
  holiday: yup.string().when("service_type", {
    is: true,
    then: (schema) => schema.required("Date is required"),
  }),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function ModalForm({
  serviceHour,
  setServiceHour,
  open,
  setOpen,
  setRefetch,
  refetch,
}) {
  const { t } = useTranslation();
  const { isLoading, updateServiceHour, addServiceHour } = ServiceHourApis();
  const handleClose = () => setOpen(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: serviceHour.id,
      day: serviceHour.day,
      holiday: serviceHour.date,
      from_time: serviceHour.from_time,
      to_time: serviceHour.to_time,
      service_type: serviceHour.service_type === "holiday",
    },
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    debugger;
    if (serviceHour.id == null && name == "service_type") {
      const isHoliday = checked;
      setServiceHour((prevState) => ({
        ...prevState,
        service_type: isHoliday ? "holiday" : "special_hours",
        day: null,
        holiday: null,
      }));
      setValue("service_type", isHoliday);
    } else {
      setServiceHour((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    data.service_type = data.service_type ? "holiday" : "special_hours";
    if (data.service_type) {
      data.day = null;
    } else {
      data.holiday = null;
    }
    debugger;

    const response =
      data.id == null
        ? await addServiceHour(data)
        : await updateServiceHour(data);

    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      handleClose();
      setRefetch(!refetch);
    }
    setLoading(false);
    console.log(data);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(15px)",
        WebkitBackdropFilter: "blur(15px)",
      }}
    >
      <Box sx={style} className="profilesection">
        <header className="modal__container">
          <div className="modal__container__icon" onClick={handleClose}>
            <ArrowBackIosIcon />
          </div>

          <h4 className="passwordChange__container__passChange">
            {t("masseur.serviceHour.addServiceHour")}
          </h4>
        </header>

        <form onSubmit={handleSubmit(onSubmit)}>
          {serviceHour.service_type == "special_hours" && (
            <div className="greenSelect">
              <label> {t("masseur.serviceHour.day")}</label>
              <select
                name="day"
                handleChange={handleChange}
                {...register("day")}
              >
                <option value="">{t("masseur.serviceHour.selectDay")}</option>
                <option value="Sunday">
                  {t("masseur.serviceHour.sunday")}
                </option>
                <option value="Monday">
                  {" "}
                  {t("masseur.serviceHour.monday")}
                </option>
                <option value="Tuesday">
                  {" "}
                  {t("masseur.serviceHour.tuesday")}
                </option>
                <option value="Wednesday">
                  {" "}
                  {t("masseur.serviceHour.wednesday")}
                </option>
                <option value="Thursday">
                  {" "}
                  {t("masseur.serviceHour.thursday")}
                </option>
                <option value="Friday">
                  {" "}
                  {t("masseur.serviceHour.friday")}
                </option>
                <option value="Saturday">
                  {" "}
                  {t("masseur.serviceHour.saturday")}
                </option>
              </select>
            </div>
          )}
          {serviceHour.service_type == "holiday" && (
            <GreenInput
              name={"holiday"}
              label={"Holiday Date"}
              type={"date"}
              handleChange={handleChange}
              error={errors.holiday?.message}
              register={register("holiday")}
            />
          )}
          <GreenInput
            label={t("masseur.serviceHour.fromTime")}
            name={"from_time"}
            type={"time"}
            handleChange={handleChange}
            error={errors.from_time?.message}
            register={register("from_time")}
          />
          <GreenInput
            label={t("masseur.serviceHour.toTime")}
            name={"to_time"}
            type={"time"}
            handleChange={handleChange}
            error={errors.to_time?.message}
            register={register("to_time")}
          />
          {serviceHour.id == null && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="default" />}
                label={t("masseur.serviceHour.holiday")}
                name="service_type"
                {...register("service_type")}
                onChange={handleChange}
              />
            </FormGroup>
          )}

          <BlueButton
            onClick={handleSubmit(onSubmit)}
            title={t("masseur.serviceHour.saveChanges")}
            loading={loading}
          />
        </form>
      </Box>
    </Modal>
  );
}
