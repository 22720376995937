import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ReviewsApis from "../../../../../../apis/client/ReviewsApis";
import DaysAgo from "../../../../../../components/general/DaysAgo";
import Rating from "@mui/material/Rating";
import Loading from "../../../../../../components/general/Loading";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Reviews = ({ masseur_slug }) => {
  const { client } = useSelector((state) => state.clientAuth);
  const { getReviews, isLoading } = ReviewsApis();
  const { t } = useTranslation();
  const [reviews, setReviews] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const fetchReviews = async () => {
    const response = await getReviews(masseur_slug);
    debugger;
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setReviews(response.data);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <React.Fragment>
      {reviews.length > 0 ? (
        <section className="review">
          <aside>
            {reviews.map((review) => (
              <article className="review__boxContainer">
                <header className="review__boxContainer__rating">
                  <section className="review__boxContainer__rating__profile">
                    <figure className="review__boxContainer__rating__profile__image">
                      <img
                        src={
                          review.client.profile_image ||
                          require("../../../../../../assets/NoPath - Copy (185).png")
                        }
                        alt="User Profile"
                      />
                    </figure>
                    <aside className="review__boxContainer__rating__profile__information">
                      <h2 className="review__boxContainer__rating__profile__information__name">
                        <h4>{review.client.screen_name}</h4>
                      </h2>
                      <section className="review__boxContainer__rating__profile__information__point">
                        <div className="review__boxContainer__rating__profile__information__point__number">
                          <Rating
                            name="hover-feedback"
                            value={review.rating}
                            precision={0.5}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                        </div>
                        <div className="review__boxContainer__rating__profile__information__point__iconbox"></div>
                      </section>
                    </aside>
                  </section>
                  <time className="review__boxContainer__rating__days">
                    <h5>
                      <DaysAgo date={review.created_at} />
                    </h5>
                  </time>
                </header>
                <p className="review__boxContainer__paragraph">
                  {review.review}
                </p>
              </article>
            ))}
            {client && (
              <section className="review__boxContainer__buttonbox">
                <Button
                  variant="contained"
                  size="small"
                  className="review__boxContainer__buttonbox__button"
                  onClick={() => navigate(`/masseur/${masseur_slug}/review`)}
                >
                  {t("client.title.PostaReview")}
                </Button>
              </section>
            )}
          </aside>
        </section>
      ) : (
        <>
          <div className="noVideo__title">
            <ReviewsIcon fontSize="large" />{" "}
            <h3> {t("client.title.noReviewyet")}</h3>
          </div>
          {client && (
            <section className="review__boxContainer__buttonbox">
              <Button
                variant="contained"
                size="small"
                className="review__boxContainer__buttonbox__button"
                onClick={() => navigate(`/masseur/${masseur_slug}/review`)}
              >
                {t("client.title.PostaReview")}
              </Button>
            </section>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default Reviews;
