import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import {
  PhotoCameraOutlined as PhotoCameraOutlinedIcon,
  Edit as EditIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from "@mui/icons-material";
import GreenInput from "../../../../../components/Form/GreenInput";
import Greentextarea from "../../../../../components/Form/GreenTextarea";
import BlueButton from "../../../../../components/Form/BlueButton";
import ProfileApis from "../../../../../apis/masseur/ProfileApis";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  username: yup.string().required("Username is required"),
  dob: yup.string().required("Birthday is required"),
  about: yup.string().required("Personal detail is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  width: 700,
  // overflowY: "auto",
};
export default function EditProfile({ profile, setProfile, setErrorMessage }) {
  const { updatePersonalInformation, isProfileLoading } = ProfileApis();
  const [profileImage, setProfileImage] = useState();
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: profile.masseur.first_name,
      last_name: profile.masseur.last_name,
      username: profile.masseur.screen_name,
      dob: profile.masseur.dob,
      about: profile.masseur.about,
      email: profile.masseur.email,
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleImages = (e) => {
    const { name, files } = e.target;
    const reader = new FileReader();
    if (name === "profile_image") {
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
    handleMasseurChange(e);
  };

  const handleMasseurChange = (e) => {
    const { value, name, files } = e.target;

    if (name == "profile_image") {
      setProfileImageFile(files[0]);
    } else {
      setProfile({
        ...profile,
        masseur: { ...profile.masseur, [name]: value },
      });
    }
  };

  const handleMasseurDetailChange = (e) => {
    const { value, name } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const onSubmit = async (data) => {
    const params = {
      ...data,
    };
    if (profileImage) {
      params["profile_image"] = profileImageFile;
    }
    const response = await updatePersonalInformation(params);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setProfile(response.data);
      handleClose();
    }
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setProfile((prevProfile) => ({
  //     ...prevProfile,
  //     [event.target.name]: typeof value === "string" ? value.split(",") : value,
  //   }));
  // };

  return (
    <main>
      <div onClick={handleOpen}>
        <EditIcon className="masseur__profile__container__clientPorfile__profile__image__editIcon__icon" />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="editprofilesection">
          <section className="profileedit">
            <header className="modal__container" style={{ width: "100%" }}>
              <div className="modal__container__icon" onClick={handleClose}>
                <ArrowBackIosIcon />
              </div>

              <h3 className="editService__modal__title">Set Up Your Profile</h3>
            </header>
            <form
              className="profileedit__container"
              onSubmit={handleSubmit(onSubmit)}
            >
              <section className="profileedit__container__body">
                <aside className="profileSetup__container__body__left">
                  <header className="profileSetup__container__body__left__profilePicture">
                    <section className="profileSetup__container__body__left__profilePicture__profilePictureBox">
                      <figure>
                        <label for="image">
                          <PhotoCameraOutlinedIcon className="profileSetup__container__body__left__profilePicture__profilePictureBox__icon" />
                          <img
                            src={
                              profileImage
                                ? profileImage
                                : profile.profile_image
                                ? profile.profile_image
                                : require("../../../../../assets/Profile_Image.jpg")
                            }
                            alt=""
                          />
                        </label>
                      </figure>
                      <input
                        type="file"
                        id="image"
                        name="profile_image"
                        onChange={(e) => handleImages(e)}
                      />
                    </section>
                    <h3>Add Profile Picture</h3>
                  </header>
                  <section className="profileSetup__container__body__left__inputBox">
                    <div>
                      <GreenInput
                        label="First Name"
                        handleChange={handleMasseurChange}
                        error={errors.first_name?.message}
                        register={register("first_name")}
                      />
                    </div>
                    <div>
                      <GreenInput
                        label="Last Name"
                        register={register("last_name")}
                        handleChange={handleMasseurChange}
                        error={errors.last_name?.message}
                      />
                    </div>
                  </section>

                  <GreenInput
                    label="Email"
                    type="email"
                    handleChange={handleMasseurDetailChange}
                    error={errors.email?.message}
                    register={register("email")}
                    readOnly
                  />
                  <GreenInput
                    label="Username"
                    handleChange={handleMasseurChange}
                    error={errors.username?.message}
                    register={register("username")}
                    readOnly
                  />
                </aside>
                <section className="profileedit__container__divider"></section>
                <aside className="profileSetup__container__body__right">
                  <GreenInput
                    label="Birthday"
                    type="date"
                    handleChange={handleMasseurChange}
                    error={errors.dob?.message}
                    register={register("dob")}
                  />
                  <Greentextarea
                    label="Personal Details"
                    register={register("about")}
                    handleChange={handleMasseurChange}
                    error={errors.about?.message}
                  />
                </aside>
              </section>
              <section
                className="profileSetup__container__nextButton"
                style={{ marginTop: "1rem" }}
              >
                <BlueButton
                  title={"Save"}
                  type="submit"
                  loading={isProfileLoading}
                />
              </section>
            </form>
          </section>
        </Box>
      </Modal>
    </main>
  );
}
