import React, { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VideoApis from "../../../../../apis/masseur/videoApis";
import Loading from "../../../../../components/general/Loading";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

export default function VideoTab({ ad_videos }) {
  const { t } = useTranslation();
  const [showUploadVideo, setShowUploadVideo] = useState(false);

  useEffect(() => {
    const checkWindowWidth = () => {
      setShowUploadVideo(window.innerWidth >= 768);
    };

    window.addEventListener("resize", checkWindowWidth);

    checkWindowWidth();

    return () => {
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, []);

  return (
    <main className="myvideo__container__section">
      {ad_videos.length > 0 ? (
        ad_videos.map((item, index) => (
          <article className="myvideo__container__section__container">
            <section
              className="myvideo__container__section__container__box"
              key={index}
            >
              <section className="myvideo__container__section__container__box__hovercontainer">
                <div className="myvideo__container__section__container__box__hovercontainer__icon">
                  <DeleteOutlineOutlinedIcon />
                </div>
              </section>
              <figure className="myvideo__container__section__container__box__image">
                <section style={{ height: "120px" }}>
                  {item.file_url ? (
                    <ReactPlayer
                      url={item.file_url}
                      controls={true}
                      volume={1}
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <ReactPlayer
                      url={item.url}
                      controls={true}
                      volume={1}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </section>
              </figure>
              <summary className="myvideo__container__section__container__box__paragraph">
                <span>{item.description}</span>
              </summary>
              <aside className="myvideo__container__section__container__box__viewscontainer">
                <section className="myvideo__container__section__container__box__viewscontainer__number">
                  <h4>5.7M {t("masseur.myVideo.view")}</h4>
                </section>
                <section className="myvideo__container__section__container__box__viewscontainer__time">
                  <h4>{t("masseur.myVideo.hour")}</h4>
                </section>
              </aside>
            </section>
          </article>
        ))
      ) : (
        <h3 className="noVideo__title"> {t("client.title.NoVideosinthisAd")}</h3>
      )}
    </main>
  );
}
