import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";

export default function TravelAdsApis() {
  const { showToast } = useToast();
  const [isTravelAdLoading, setIsTravelAdLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getTravelAds() {
    setIsTravelAdLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/travel_ads?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsTravelAdLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsTravelAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function newTravelAd() {
    setIsTravelAdLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/travel_ads/new?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => {
        setIsTravelAdLoading(false);
        onSuccess(response, showToast, "Ready To Create Travel Ad!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsTravelAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }
  async function createTravelAd(ad) {
    setIsTravelAdLoading(true);
    return await axios
      .post(`${SERVER_API}/masseurs/travel_ads?locale=${language.id}`, ad, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => {
        setIsTravelAdLoading(false);
        showToast({
          message: response.data.message,
          flag: "Created Successfully",
        });
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsTravelAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function getTravelAd(id) {
    setIsTravelAdLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/travel_ads/${id}?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => {
        setIsTravelAdLoading(false);
        onSuccess(response, showToast, "Travel Ad fetched");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsTravelAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateTravelAd(ad, id) {
    setIsTravelAdLoading(true);
    return await axios
      .put(`${SERVER_API}/masseurs/travel_ads/${id}`, ad, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsTravelAdLoading(false);
        onSuccess(response, showToast, "Updated Travel Ad!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsTravelAdLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getTravelAd,
    newTravelAd,
    getTravelAds,
    createTravelAd,
    updateTravelAd,
    isTravelAdLoading,
  };
}
