import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import Loading from "../../../../../components/general/Loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MasseurAdForm from "../Components/ManageAdForm";
import TravelAdsApis from "../../../../../apis/masseur/TravelAdsApis";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NewTravelAd() {
  const { t } = useTranslation();
  const { createTravelAd, isAdLoading } = TravelAdsApis();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [newTravelAd, setNewTravelAd] = useState({
    title: null,
    about_my_services: null,
    address: null,
    city: null,
    state: null,
    country: null,
    start_date: null,
    expire_date: null,
    service_ids: [],
    massage_ids: [],
    rate_ids: [],
    service_hour_ids: [],
    photo_ids: [],
    video_ids: [],
    starting_price: null,
    time_duration: null,
    status: "active",
  });

  const onSubmit = async (params) => {
    const response = await createTravelAd(params);

    if (response.data) {
      navigate("/masseur/travel-ads");
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  if (isAdLoading) return <Loading />;
  if (errorMessage) return null;

  return (
    <MasseurAdForm
      onSubmit={onSubmit}
      travelAd={newTravelAd}
      setTravelAd={setNewTravelAd}
      title={t("masseur.myAd.newForm")}
    />
  );
}
