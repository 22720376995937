import React, { useState, useEffect } from "react";
import Loading from "../../../../../components/general/Loading";
import ServiceHourApis from "../../../../../apis/masseur/serviceHourApis";
import EditServiceHourModal from "./components/EditServiceHourModal";
import { useTranslation } from "react-i18next";

export default function HolidayTab({ refetch, setRefetch }) {
  const { t } = useTranslation();

  const [serviceHour, setServiceHour] = useState([]);
  const { isLoading, getServiceHour } = ServiceHourApis();
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchServiceHours = async () => {
    const response = await getServiceHour("holiday");

    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setServiceHour(response.data);
    }
  };

  useEffect(() => {
    fetchServiceHours();
  }, [refetch]);

  if (isLoading) return <Loading />;
  if (errorMessage) return null;

  return (
    <React.Fragment>
      {serviceHour.length > 0 ? (
        <table className="serviceHour__table">
          {serviceHour.map((data) => (
            <section className="serviceHour__table__container">
              <h4 className="serviceHour__table__days">
                {" "}
                {t("client.title.Date")}
              </h4>
              <div className="serviceHour__table__textField">
                <div className="serviceHour__table__textField__greenInput">
                  <label> {t("client.title.Time")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="date" value={data.holiday} />
                  </div>
                </div>
              </div>
              <h4 className="serviceHour__table__title">
                {" "}
                {t("client.title.From")}
              </h4>
              <div className="serviceHour__table__textField">
                <div className="serviceHour__table__textField__greenInput">
                  <label> {t("client.title.From")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="time" value={data.from_time} />
                  </div>
                </div>
              </div>
              <h4 className="serviceHour__table__title">
                {" "}
                {t("client.title.To")}
              </h4>
              <div className="serviceHour__table__textField">
                <div className="serviceHour__table__textField__greenInput">
                  <label> {t("client.title.Time")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="time" value={data.to_time} />
                  </div>
                </div>
              </div>
              <EditServiceHourModal
                data={data}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            </section>
          ))}
        </table>
      ) : (
        <h1 className="noServiceHour_title"> {t("client.title.CreateHoliday")}</h1>
      )}
    </React.Fragment>
  );
}
