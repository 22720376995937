import React, { useEffect, useState } from "react";
import VideoApis from "../../../../../../apis/masseur/videoApis";
import Loading from "../../../../../../components/general/Loading";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  InsertLink as InsertLinkIcon,
} from "@mui/icons-material";
import UploadVideo from "../UploadVideo";

export default function Video() {
  const { t } = useTranslation();
  const [showUploadVideo, setShowUploadVideo] = useState(false);
  const [videos, setVideos] = useState([]);
  const { isVideoLoading, getVideos } = VideoApis();
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchVideos = async () => {
    const response = await getVideos();
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setVideos(response.data);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    const checkWindowWidth = () => {
      setShowUploadVideo(window.innerWidth >= 900);
    };

    window.addEventListener("resize", checkWindowWidth);

    checkWindowWidth();

    return () => {
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, []);

  if (isVideoLoading) return <Loading />;
  if (errorMessage) return null;

  return (
    <React.Fragment>
      {isVideoLoading ? (
        <div className="circularLoading">
          <Loading />
        </div>
      ) : (
        <section className="myvideo__container__section">
          {videos.length === 0 ? (
            <div className="myvideo__container__section__noVideo">
              <h1>No Videos</h1>
            </div>
          ) : (
            <div className="myvideo__container__section__container">
              <section className="myvideo__container__section__container__greenbox">
                <div className="myvideo__uploadvideo__section__greenbox__container">
                  <div className="myvideo__uploadvideo__section__greenbox__container__textfileldcontainer">
                    <div className="myvideo__uploadvideo__section__greenbox__container__textfileldcontainer__icon">
                      <InsertLinkIcon sx={{ color: "#3d3b3b" }} />
                    </div>
                    <div className="myvideo__uploadvideo__section__greenbox__container__textfileldcontainer__textfiled">
                      <input placeholder="example.com/share-link" />
                    </div>
                  </div>
                  <div className="myvideo__uploadvideo__section__greenbox__container__buttonbox">
                    <Button className="myvideo__uploadvideo__section__greenbox__container__buttonbox__button">
                      {t("masseur.myVideo.upload")}
                    </Button>
                  </div>
                </div>
              </section>
              {videos.map((item, index) => (
                <div
                  className="myvideo__container__section__container__box"
                  key={index}
                >
                  <section className="myvideo__container__section__container__box__hovercontainer">
                    <div className="myvideo__container__section__container__box__hovercontainer__icon">
                      <DeleteOutlineOutlinedIcon />
                    </div>
                  </section>
                  <figure className="myvideo__container__section__container__box__video">
                    {item.file_url ? (
                      <ReactPlayer
                        url={item.file_url}
                        controls={true}
                        volume={1}
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : (
                      <ReactPlayer
                        url={item.url}
                        controls={true}
                        volume={1}
                        width={"100%"}
                        height={"100%"}
                      />
                    )}
                  </figure>

                  <p className="myvideo__container__section__container__box__paragraph">
                    {item.description}
                  </p>
                  <div className="myvideo__container__section__container__box__viewscontainer">
                    <h5 className="myvideo__container__section__container__box__viewscontainer__number">
                      5.7M {t("masseur.myVideo.view")}
                    </h5>
                    <h5 className="myvideo__container__section__container__box__viewscontainer__time">
                      {t("masseur.myVideo.hour")}
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          )}
          {showUploadVideo && (
            <section className="myvideo__uploadvideo">
              <UploadVideo setVideos={setVideos} />
            </section>
          )}
        </section>
      )}
    </React.Fragment>
  );
}
