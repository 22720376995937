import React from "react";
import CardOptions from "./CardOptions";
import { useTranslation } from "react-i18next";

const BasicModalOptions = ({ levelPackage }) => {
  const { t } = useTranslation();

  return (
    <section className="basicCardModal__body__options">
      <aside className="basicCardModal__body__options__Box">
        <CardOptions
          description={t("masseur.myLevel.uploadImages", {
            images_limit: levelPackage.images_limit,
          })}
        />
        <CardOptions
          description={t("masseur.myLevel.uploadVideos", {
            videos_limit: levelPackage.video_count,
          })}
        />
        <CardOptions description={t("masseur.myLevel.emailSupport")} />
        <CardOptions description={t("masseur.myLevel.cancelAnytime")} />
      </aside>
      <aside className="basicCardModal__body__options__Box">
        <CardOptions
          description={t("masseur.myLevel.travelAd", {
            travel_ad: levelPackage.travel_ad,
          })}
        />
        <CardOptions
          description={t("masseur.myLevel.localAd", {
            local_ad: levelPackage.local_ad,
          })}
        />
        <CardOptions
          description={t("masseur.myLevel.basicModal.specialClient")}
        />
        <CardOptions
          description={t("masseur.myLevel.serviceHourSchedule", {
            service_hour_schedule: levelPackage.service_hour_schedule,
          })}
        />
        <CardOptions
          description={t("masseur.myLevel.serviceAndMassageType", {
            service_and_massage_types: levelPackage.service_and_massage_types,
          })}
        />
      </aside>
      <aside className="basicCardModal__body__options__Box">
        <CardOptions description={t("masseur.myLevel.basicModal.realTime")} />
        <CardOptions description={t("masseur.myLevel.basicModal.contact")} />
        <CardOptions description={t("masseur.myLevel.basicModal.support")} />
      </aside>
    </section>
  );
};

export default BasicModalOptions;
