import React, { useState, useEffect } from "react";
import RateTableHeader from "./RateTableHeader/index";
import RateTable from "./RateTable/index";
import RateApis from "../../../../apis/masseur/RateApis";
import Loading from "../../../../components/general/Loading";

export default function Rate() {
  const { deleteRate, getRates, isRateLoading } = RateApis();
  const [rates, setRates] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchRates = async () => {
    const response = await getRates();
    if (response.error) {
      setErrorMessage(response.errorMessage);
    } else {
      setRates(response.data);
    }
  };

  const destroyRate = async (id) => {
    const response = await deleteRate(id);
    if (response.error) {
      setErrorMessage(response.errorMessage);
    } else {
      fetchRates();
    }
  };
  useEffect(() => {
    fetchRates();
  }, []);

  if (errorMessage) return null;

  return (
    <main className="rate__tablecontainer">
      {isRateLoading ? (
        <div className="circularLoading">
          <Loading />
        </div>
      ) : (
        <>
          <header className="rate__tablecontainer__tableheader">
            <RateTableHeader fetchRates={fetchRates} />
          </header>
          <section className="rate__tablecontainer__table">
            <RateTable
              rates={rates}
              fetchRates={fetchRates}
              destroyRate={destroyRate}
            />
          </section>
        </>
      )}
    </main>
  );
}
