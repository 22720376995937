import SignUp from "../pages/auth/SignUp";
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";

//client profile setup
import ClientPersonalInformation from "../pages/app/client/profileSetup/ClientPersonalInformation";
import ClientPhoneVerificaiton from "../pages/app/client/profileSetup/ClientPhoneVerificaiton";

//client components without authentication
import Home from "../pages/app/client/Home";
import MasseurPortfolio from "../pages/app/client/MasseurPortfolio";
import AdProfile from "../pages/app/client/AdProfile";
import Review from "../pages/app/client/Review";

//client components for privateRouting
import Favourite from "../pages/app/client/Favourite/Favourite";
import Profile from "../pages/app/client/Profile";

import EditService from "../pages/app/masseur/Profile/components/EditService";
import Ads from "../pages/app/masseur/Ads";
import NewAd from "../pages/app/masseur/Ads/NewAd";
import UpdateAd from "../pages/app/masseur/Ads/UpdateAd";

//Masseur Profile Setup
import MasseurPersonalInformation from "../pages/app/masseur/profileSetup/MasseurPersonalInformation";
import MasseurPhoneVerification from "../pages/app/masseur/profileSetup/MasseurPhoneVerificaiton";
// import SuccessMessage from "../pages/app/profileSetup/SuccessMessage";
import ServicesOffer from "../pages/app/masseur/profileSetup/ServicesOffer";
import SelectLevel from "../pages/app/masseur/SelectLevel";
import SetupSelectLevel from "../pages/app/masseur/profileSetup/SetupSelectLevel";

//masseur components
import MasseurProfile from "../pages/app/masseur/Profile";
import Video from "../pages/app/masseur/Video";
import Rate from "../pages/app/masseur/Rate";
import ClientReview from "../pages/app/masseur/ClientReview";
import PChat from "../pages/app/masseur/PChat";
import MasseurPage from "../components/PageWrapper/MasseurPage";
import ServiceHour from "../pages/app/masseur/ServiceHour";
import MyPhoto from "../pages/app/masseur/MyPhoto";
import Support from "../pages/app/masseur/Support";
import MyBatch from "../pages/app/masseur/MyBatch";
import StripePayment from "../pages/app/StripePayment";
import StripeCallback from "../pages/app/StripePayment/StripeCallback";
import NotFound from "../components/general/NotFound";
import TravelAd from "../pages/app/masseur/TravelAd";
import NewTravelAd from "../pages/app/masseur/TravelAd/New";
import EditTravelAd from "../pages/app/masseur/TravelAd/Edit";

export const routes = [
  {
    path: "/signin",
    component: SignIn,
    isPrivate: false,
  },
  {
    path: "/signup",
    component: SignUp,
    isPrivate: false,
  },

  // masseur routing
  {
    name: "masseur",
    path: "/masseur",
    component: MasseurPage,
    privateRouting: "Masseur",
    subRoutes: [
      {
        name: "Profile",
        path: "profile",
        component: MasseurProfile,
        isPrivate: true,
      },
      {
        name: "Video",
        path: "videos",
        component: Video,
        isPrivate: true,
      },
      {
        name: "Rate",
        path: "rates",
        component: Rate,
        isPrivate: true,
      },
      {
        name: "Review",
        path: "/masseur/review",
        component: ClientReview,
        isPrivate: true,
      },
      {
        name: "Ads",
        path: "ads",
        component: Ads,
        isPrivate: true,
      },
      {
        name: "newAd",
        path: "newAd",
        component: NewAd,
        isPrivate: true,
      },
      {
        name: "updateAd",
        path: "ads/:id",
        component: UpdateAd,
        isPrivate: true,
      },
      {
        name: "traveAd",
        path: "travel-ads",
        component: TravelAd,
        isPrivate: true,
      },
      {
        name: "newTraveAd",
        path: "travel-ads/new",
        component: NewTravelAd,
        isPrivate: true,
      },
      {
        name: "updateTraveAd",
        path: "travel-ads/:id/edit",
        component: EditTravelAd,
        isPrivate: true,
      },
      {
        name: "Chat",
        path: "chat",
        component: PChat,
        isPrivate: true,
      },

      {
        name: "ServiceHour",
        path: "serviceHours",
        component: ServiceHour,
        isPrivate: true,
      },
      {
        name: "MyPhoto",
        path: "Photos",
        component: MyPhoto,
        isPrivate: true,
      },
      {
        name: "Support",
        path: "support",
        component: Support,
        isPrivate: true,
      },
      {
        name: "level",
        path: "level",
        component: SelectLevel,
        isPrivate: true,
      },
      {
        name: "mybatch",
        path: "batches",
        component: MyBatch,
        isPrivate: true,
      },
      {
        name: "editservices",
        path: "editservices",
        component: EditService,
      },
    ],
  },

  {
    name: "MasseurPortfolio",
    path: "masseur/:masseur_slug/portfolio",
    component: MasseurPortfolio,
    privateRouting: "customer",
  },
  {
    name: "Information",
    path: "masseur/profileSetup/personal_info",
    component: MasseurPersonalInformation,
    privateRouting: "MasseurProfileSetup",
  },
  {
    name: "adProfile",
    path: "masseurs/:masseur_slug/ads/:ad_slug",
    component: AdProfile,
    privateRouting: "customer",
  },
  {
    privateRouting: "MasseurProfileSetup",
    name: "OTP",
    path: "masseur/profileSetup/phone_verification",
    component: MasseurPhoneVerification,
  },
  {
    privateRouting: "MasseurProfileSetup",
    name: "servicesOffer",
    path: "masseur/profileSetup/service_offer",
    component: ServicesOffer,
  },
  {
    privateRouting: "MasseurProfileSetup",
    name: "selectLevel",
    path: "masseur/profileSetup/subscription",
    component: SetupSelectLevel,
  },

  {
    privateRouting: "MasseurProfileSetup",
    name: "Payment",
    path: "masseur/payment/price/:price/:package_id/:frequency_id",
    component: StripePayment,
  },

  {
    privateRouting: "MasseurProfileSetup",
    name: "Payment",
    path: "masseur/payment/:masseur_id/:package_id/:price",
    component: StripeCallback,
  },

  //client profile setup routing
  {
    name: "ClientInformation",
    path: "client/profileSetup/personal_info",
    component: ClientPersonalInformation,
    privateRouting: "clientProfileSetup",
  },
  {
    name: "OTP",
    path: "client/profileSetup/phone_verification",
    component: ClientPhoneVerificaiton,
    privateRouting: "clientProfileSetup",
  },

  {
    name: "OTP",
    path: "client/profileSetup/phone_verification",
    component: ClientPhoneVerificaiton,
    privateRouting: "clientProfileSetup",
  },

  //client routes
  {
    path: "/masseur/:masseur_slug/review",
    component: Review,
    isPrivate: true,
    privateRouting: "client",
  },
  {
    path: "/notFound",
    component: NotFound,
    isPrivate: false,
  },

  {
    name: "",
    path: "/",
    component: Home,
  },

  {
    name: "Favourite",
    path: "/favourite",
    component: Favourite,
    privateRouting: "client",
  },

  {
    name: "Edit Profile",
    path: "/:slug/profile",
    component: Profile,
    privateRouting: "client",
  },

  // {
  //   name: "Chat",
  //   path: "/client/:slug/chat",
  //   component: PChat,
  //   privateRouting: "client",
  // },
];

// export const routes = [
// {
//   path: "/",
//   component: LandingPage,
//   isPrivate: false,
// },
// {
//   path: "/signin",
//   component: SignIn,
//   isPrivate: false,
// },
// {
//   path: "/signup",
//   component: SignUp,
//   isPrivate: false,
// },
// {
//   path: "/profile/edit",
//   component: EditProfile,
//   isPrivate: false,
// },
// {
//   path: "/profile/setup",
//   component: ProfileSetup,
//   isPrivate: false,
// },
// {
//   path: "/review",
//   component: Review,
//   isPrivate: false,
// },
// {
//   path: "/password/change",
//   component: ResetPassword,
//   isPrivate: false,
// },
// // {
// //   path: "/chat",
// //   component: Chat,
// //   isPrivate: false,
// // },
// {
//   path: "/masseur/portfolio",
//   component: MasseurPortfolio,
//   isPrivate: false,
// },
// // {
// //   path: "/chat/message/:id",
// //   component: Message,
// // },
// {
//   path: "/rate",
//   component: Rate,
//   isPrivate: false,
// },
// ];
