import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import Video from "./Video/index";
import UploadVideo from "./UploadVideo";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MyVideoTab() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [showUploadTab, setShowUploadTab] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setShowUploadTab(window.innerWidth <= 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main>
      <Box className="tab__container">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            TabIndicatorProps={{
              sx: { backgroundColor: "#193D66", height: 2 },
            }}
            sx={{
              "& button": {
                color: "#758ba3",
                fontWeight: "600",
                fontSize: "1rem",
                textTransform: "capitalize",
                marginRight: "1rem",
              },
              "& button.Mui-selected": {
                color: "#193D66",
                fontWeight: "600",
                fontSize: "1rem",
              },
            }}
          >
            <Tab label={t("masseur.myVideo.myVideoTab")} {...a11yProps(0)} />
            {showUploadTab && <Tab label="Upload Video" {...a11yProps(1)} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Video />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UploadVideo />
        </TabPanel>
      </Box>
    </main>
  );
}
