import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import { onSuccess, onError, formDataToObject } from "./request";
import { store } from "../../redux/store";
import {
  masseurLogoutSuccess,
  masseurData,
} from "../../redux/slices/auth/masseur";
import { setSubscription } from "../../redux/slices/subscription";
import { useSelector } from "react-redux";

export default function ProfileApis() {
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);
  const navigate = useNavigate();
  const { showToast } = useToast();

  async function getProfile() {
    setIsProfileLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/profile?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updatePersonalInformation(masseur) {
    setIsProfileLoading(true);
    const formData = new FormData();
    for (const property in masseur) {
      if (property == "profile_image") {
        formData.append(property, masseur[property]);
      } else {
        formData.append(`masseur[${property}]`, masseur[property]);
      }
    }

    return await axios
      .put(
        `${SERVER_API}/masseurs/profile/update_personal_information?locale=${language.id}`,
        formData,
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated masseur information!");
        setIsProfileLoading(false);
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateMasseurAddress(address) {
    return await axios
      .put(
        `${SERVER_API}/masseurs/profile/update_address?locale=${language.id}`,
        { masseur: { ...address } },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated masseur address!");
        return response.data;
      })
      .catch((error) => {
        onError(error, showToast);
      });
  }

  async function updatePassword(params) {
    setIsProfileLoading(true);

    const response = await axios
      .put(
        `${SERVER_API}/masseurs/profile/update_password`,
        { masseur: { ...params } },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Password Updated Successfully!");
        store.dispatch(masseurLogoutSuccess());
        navigate("/signin");
        setIsProfileLoading(false);
      })
      .catch((error) => {
        onError(error, showToast);
        setIsProfileLoading(false);
      });
  }

  const sendServicesAndMassageTypes = async (params) => {
    setIsProfileLoading(true);
    const response = await axios
      .put(
        `${SERVER_API}/masseurs/profile/update_massage_and_services?locale=${language.id}`,
        { ...params },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated Services and Massage Types");
        return response.data;
      })
      .catch((error) => {
        onError(error, showToast);
      });
    setIsProfileLoading(false);
  };

  const updateMasseurDetails = async (masseur_details) => {
    setIsProfileLoading(true);
    const formData = new FormData();
    for (const property in masseur_details) {
      if (property == "profile_image") {
        formData.append(property, masseur_details[property]);
      }
      if (property == "masseur") {
        const masseur = masseur_details[property];
        for (const entry in masseur) {
          formData.append(`masseur[${entry}]`, masseur[entry]);
        }
      } else {
        if (property == "masseur_detail") {
          const masseur_detail = masseur_details[property];
          for (const masseur_d in masseur_detail) {
            formData.append(
              `masseur_detail[${masseur_d}]`,
              masseur_detail[masseur_d]
            );
          }
        }
      }
    }
    return await axios
      .put(
        `${SERVER_API}/masseurs/profile/update_masseur_and_masseur_details?locale=${language.id}`,
        formData,
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        onSuccess(response, showToast, "masseur Data is updated!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  };

  const masseurSignOut = async () => {
    setIsProfileLoading(true);
    await axios
      .delete(`${SERVER_API}/masseurs/logout`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Logout Successfully!");
        store.dispatch(masseurLogoutSuccess());
        navigate("/signin");
        setIsProfileLoading(false);
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
      });
  };

  async function updateMasseurDemographics(demographic) {
    return await axios
      .put(`${SERVER_API}/masseurs/profile/demographics_details?locale=${language.id}`, demographic, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated masseur demographics!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updatePersonalization(personalization) {
    return await axios
      .put(
        `${SERVER_API}/masseurs/profile/update_personalization?locale=${language.id}`,
        { masseur_detail: personalization },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated masseur personalization!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function getPersonalization() {
    setIsProfileLoading(true);

    return await axios
      .get(`${SERVER_API}/masseurs/profile/get_personlization?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        onSuccess(response, showToast, "get masseur personalization!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsProfileLoading(false);
      });
  }
  async function getCurrentSubscription() {
    setIsProfileLoading(true);

    return await axios
      .get(`${SERVER_API}/masseurs/profile/current_subscription?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        const sub = response.data;
        store.dispatch(
          setSubscription({
            package_id: sub.package_id,
            amount: sub.amount,
            frequency: sub.frequency,
            active: sub.active,
            expire_date: sub.expire_date,
          })
        );
        setIsProfileLoading(false);
        onSuccess(response, showToast, "get masseur subscription!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsProfileLoading(false);
      });
  }

  async function currentProfile() {
    setIsProfileLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/profile/current_profile?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        // onSuccess(response, showToast, "Data fetched!");
        store.dispatch(
          masseurData({
            masseur: response.data,
          })
        );
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getProfile,
    updateMasseurAddress,
    updatePassword,
    sendServicesAndMassageTypes,
    updateMasseurDetails,
    masseurSignOut,
    updateMasseurDemographics,
    getPersonalization,
    updatePersonalization,
    getCurrentSubscription,
    currentProfile,
    updatePersonalInformation,
    isProfileLoading,
  };
}
