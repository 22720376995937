import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/general/Loading";
import ServiceHourApis from "../../../../../apis/masseur/serviceHourApis";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function ServiceHourTab({ serviceHours }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <article className="serviceHour__table">
        {serviceHours.length > 0 ? (
          serviceHours.map((data) => (
            <section className="serviceHour__table__container">
              <h3 className="serviceHour__table__days">
                {data.service_type == "holiday"
                  ? `${dayjs(data.holiday).format("d MMM, YYYY")}`
                  : data.day}
              </h3>
              <h4 className="serviceHour__table__title">
                {" "}
                {t("client.adsProfile.from")}
              </h4>
              <section className="serviceHour__table__textField">
                <aside className="serviceHour__table__textField__greenInput">
                  <label>{t("client.adsProfile.time")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="time" value={data.from_time} />
                  </div>
                </aside>
              </section>
              <h4 className="serviceHour__table__title">
                {t("client.adsProfile.to")}
              </h4>
              <section className="serviceHour__table__textField">
                <aside className="serviceHour__table__textField__greenInput">
                  <label>{t("client.adsProfile.time")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="time" value={data.to_time} />
                  </div>
                </aside>
              </section>
            </section>
          ))
        ) : (
          <h3 className="noVideo__title">
            {" "}
            {t("client.title.NoServiceHourinthisAd")}
          </h3>
        )}
      </article>
      <section className="responsivServiceHour">
        {serviceHours.length > 0 ? (
          serviceHours.map((data) => (
            <aside className="responsivServiceHour__container">
              <section className="responsivServiceHour__container__top">
                <h3 className="serviceHour__table__days">{data.day}</h3>
              </section>
              <label className="responsivServiceHour__container__label">
                {t("client.adsProfile.date")}
              </label>
              <section className="serviceHour__table__textField">
                <aside className="serviceHour__table__textField__greenInput">
                  <label>{t("client.adsProfile.addDate")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="date" value={data.date} />
                  </div>
                </aside>
              </section>
              <label className="responsivServiceHour__container__label">
                {t("client.adsProfile.time")}
              </label>
              <section className="responsivServiceHour__container__time">
                <section className="serviceHour__table__textField">
                  <aside className="serviceHour__table__textField__greenInput">
                    <label>{t("client.adsProfile.from")}</label>
                    <div className="serviceHour__table__textField__greenInput__inputBox">
                      <input type="time" value={data.from_time} />
                    </div>
                  </aside>
                </section>
                <section className="serviceHour__table__textField">
                  <aside className="serviceHour__table__textField__greenInput">
                    <label>{t("client.adsProfile.to")}</label>
                    <div className="serviceHour__table__textField__greenInput__inputBox">
                      <input type="time" value={data.to_time} />
                    </div>
                  </aside>
                </section>
              </section>
            </aside>
          ))
        ) : (
          <h3 className="noVideo__title">No ServiceHour in this Ad</h3>
        )}
      </section>
    </React.Fragment>
  );
}
