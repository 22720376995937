import React, { useState, useEffect } from "react";
import Loading from "../../../../../components/general/Loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ManageAdForm from "../Components/ManageAdForm";
import TravelAdsApis from "../../../../../apis/masseur/TravelAdsApis";
import { useParams } from "react-router-dom";

export default function EditTravelAd() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isTravelAdLoading, getTravelAd, updateTravelAd } = TravelAdsApis();
  const navigate = useNavigate();

  const [travelAd, setTravelAd] = useState({
    id: null,
    title: null,
    about_my_services: null,
    address: null,
    city: null,
    state: null,
    country: null,
    start_date: null,
    expire_date: null,
    service_ids: [],
    massage_ids: [],
    rate_ids: [],
    service_hour_ids: [],
    photo_ids: [],
    video_ids: [],
    starting_price: null,
    time_duration: null,
    status: "active",
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchAd = async () => {
    const response = await getTravelAd(id);
    if (response.data) {
      const data = response.data;

      const params = {
        id: data.id,
        title: data.title,
        about_my_services: data.about_my_services,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        start_date: data.start_date,
        expire_date: data.expire_date,
        starting_price: data.starting_price,
        time_duration: data.time_duration,
        service_ids: data.services.map((service) => ({
          value: service.id,
          label: service.name,
        })),
        massage_ids: data.massage_types.map((massage_type) => ({
          value: massage_type.id,
          label: massage_type.name,
        })),
        rate_ids: data.rates.map((rate) => rate.id),
        service_hour_ids: data.service_hours.map(
          (service_hour) => service_hour.id
        ),
        photo_ids: data.ad_photos.map((photo) => photo.photo_id),
        video_ids: data.ad_videos.map((video) => video.video_id),
      };
      setTravelAd(params);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const onSubmit = async (params) => {
    const response = await updateTravelAd(params, params.id);

    if (response.data) {
      navigate("/masseur/travel-ads");
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchAd();
  }, []);

  if (isTravelAdLoading) return <Loading />;
  if (errorMessage) return null;

  return (
    <ManageAdForm
      onSubmit={onSubmit}
      travelAd={travelAd}
      setTravelAd={setTravelAd}
      title={t("masseur.myAd.newForm")}
    />
  );
}
