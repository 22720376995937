import React from "react";
import { useTranslation } from "react-i18next";
import {
  Email as EmailIcon,
  Call as CallIcon,
  FmdGood as FmdGoodIcon,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const About = ({ profile }) => {
  const { t } = useTranslation();

  return (
    <article className="about">
      <section className="about__email__container">
        <div className="about__email__container__icon">
          <EmailIcon />
        </div>
        <h4 className="about__email__container__name">{profile?.email}</h4>
      </section>
      <section className="about__contact__container">
        <div className="about__contact__container__callIcon">
          <CallIcon />
        </div>
        <figure className="about__contact__container__country__image">
          <img
            src={require("../../../../../../assets/NoPath - Copy (2).png")}
          />
        </figure>
        <h4 className="about__contact__container__phoneNumber">
          {profile?.phone}
        </h4>
      </section>
      <section className="about__locationInformation">
        <summary className="adsProfile__profileData__description">
          {/* <div className="adsProfile__profileData__location">
            <LocationOnIcon className="landingPage__container__searchBox__locationButton__icon" />
            <p>
              {" "}
              {profile?.street} {profile?.country}
            </p>
          </div> */}
          {/* <div className="adsProfile__profileData__location">
                <SmokingRoomsIcon />
                <h5>Smoking Frequancies </h5>
              </div> */}
          <h4 className="adsProfile__profileData__Information">
            {t("client.adsProfile.smokingFrequency")} :{" "}
            <span className="font__style">
              {profile.masseur_detail.smoking_frequency || "No Set"}
            </span>
          </h4>
          <h4 className="adsProfile__profileData__Information">
            {t("client.adsProfile.drugFrequancy")} :{" "}
            <span className="font__style">
              {profile.masseur_detail.drug_frequency || "No Set"}
            </span>
          </h4>
          {/* <figure className="adsProfile__profileData__location">
                <img
                  src={require("../../../../assets/sexual-orientation-symbol-icon-free-vector.jpg")}
                  className="adsProfile__profileData__img"
                  style={{ width: "35px" }}
                />
                <h5>Sexual Orientation</h5>
              </figure> */}
          <h4 className="adsProfile__profileData__Information">
            {t("client.adsProfile.sexualOrientation")} :{" "}
            <span className="font__style">
              {profile.masseur_detail.sexual_orientation || "No Set"}
            </span>
          </h4>
          <h4 className="adsProfile__profileData__Information">
            {t("client.adsProfile.ethnicity")} :{" "}
            <span className="font__style">
              {profile.masseur_detail.ethnicity || "No Set"}
            </span>
          </h4>
          <h4 className="adsProfile__profileData__Information">
            {t("client.adsProfile.language")} :{" "}
            <span className="font__style">
              {profile.masseur_detail.languages || "No Set"}
            </span>
          </h4>
          <h4 className="adsProfile__profileData__Information">
            {t("client.adsProfile.workLocation")} :{" "}
            <span className="font__style">
              {profile.masseur_detail.work_locations || "No Set"}
            </span>
          </h4>
          <h4 className="adsProfile__profileData__Information">
            {t("client.adsProfile.workSurface")}:{" "}
            <span className="font__style">
              {" "}
              {profile.masseur_detail.work_surfaces || "No Set"}
            </span>
          </h4>
          <section className="landingPage__container__cardsContainer__cards__card__services">
            <h5> {t("client.adsProfile.personalities")}</h5>
            <aside className="landingPage__container__cardsContainer__cards__card__services__offers">
              <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                {t("client.adsProfile.weigth")} :{" "}
                <span className="font__style">
                  {profile.masseur_detail.weight || "No Set"}{" "}
                  {profile.masseur_detail.weight &&
                    profile.masseur_detail.weight_unit}
                </span>
              </h4>
              <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                {t("client.adsProfile.heigth")} :{" "}
                <span className="font__style">
                  {" "}
                  {profile.masseur_detail.height || "No Set"}{" "}
                  {profile.masseur_detail.height &&
                    profile.masseur_detail.height_unit}
                </span>
              </h4>

              <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                {t("client.adsProfile.hairColor")} :{" "}
                <span className="font__style">
                  {" "}
                  {profile.masseur_detail.hair_color || "No Set"}
                </span>
              </h4>
              <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                {t("client.adsProfile.eyeColor")} :{" "}
                <span className="font__style">
                  {" "}
                  {profile.masseur_detail.eye_color || "No Set"}
                </span>
              </h4>
              <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                {t("client.adsProfile.bodyHair")} :{" "}
                <span className="font__style">
                  {profile.masseur_detail.eye_color || "No Set"}
                </span>
              </h4>
            </aside>
          </section>
        </summary>
      </section>
      <section className="about__container">
        <h4 className="about__container__title">{t("client.title.About")}</h4>
        <aside className="about__container__paragraph">
          <p>{profile?.about}</p>
        </aside>
      </section>
    </article>
  );
};
export default About;
