import { SERVER_API } from "../helpers/variable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "../context/ToastContext";
import { store } from "../redux/store";
import {
  masseurLoginSuccess,
  masseurLogoutSuccess,
} from "../redux/slices/auth/masseur";

export default function MasseurAuth() {
  const { showToast } = useToast();
  const navigate = useNavigate();

  async function masseurSignup(masseur) {
    await axios
      .post(`${SERVER_API}/masseurs/sign_up`, { masseur: { ...masseur } })
      .then((response) => {
        showToast({ message: response.data.message, flag: "success" });
        navigate("/signin");
      })
      .catch((error) => {
        showToast({ message: error.message, flag: "error" });
      });
  }

  async function masseurSignin(masseur) {
    await axios
      .post(`${SERVER_API}/masseurs/login`, { masseur: { ...masseur } })
      .then((response) => {
        store.dispatch(
          masseurLoginSuccess({
            masseur: response.data,
            token: response.headers.authorization,
          })
        );
        showToast({ message: "Succesfully SignedIn!", flag: "success" });
        if (response.data.active) {
          navigate("/masseur/profile");
        } else {
          navigate(`/masseur/profileSetup/${response.data.setup_stage}`);
        }
      })
      .catch((error) => {
        showToast({ message: error.message, flag: "error" });
      });
  }

  return {
    masseurSignin,
    masseurSignup,
  };
}
