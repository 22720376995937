import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";

export default function MasseurAds() {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function masseurAds(masseur_slug) {
    setIsLoading(true);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/masseur_ads?locale=${language.id}&masseur_slug=${masseur_slug}`
        )
        .then((response) => response)
        .then((response) => {
          setIsLoading(false);
          onSuccess(response, showToast, "Ads are loaded!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          setIsLoading(false);
          onError(error, showToast);
          return {
            data: null,
            errorMessage: error.response.data.message,
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  }

  async function masseurAd(masseur_slug, ad_slug) {
    setIsLoading(true);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/masseur_ads/ad?locale=${language.id}&ad_slug=${ad_slug}&masseur_slug=${masseur_slug}`
        )
        .then((response) => response)
        .then((response) => {
          setIsLoading(false);
          onSuccess(response, showToast, "Ad is loaded!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          setIsLoading(false);
          onError(error, showToast);
          return {
            data: null,
            errorMessage: error.response.data.message,
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  }

  async function masseurProfile(masseur_slug) {
    setIsLoading(true);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/masseur_ads/masseur_profile?locale=${language.id}&masseur_slug=${masseur_slug}`
        )
        .then((response) => response)
        .then((response) => {
          setIsLoading(false);
          onSuccess(response, showToast, "Masseur profile is loaded!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          setIsLoading(false);
          onError(error, showToast);
          return {
            data: null,
            errorMessage: error.response.data.message,
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  }

  async function masseur(masseur_slug) {
    setIsLoading(true);
    try {
      return await axios
        .get(
          `${SERVER_API}/clients/masseur_ads/masseur?locale=${language.id}&masseur_slug=${masseur_slug}`
        )
        .then((response) => response)
        .then((response) => {
          setIsLoading(false);
          onSuccess(response, showToast, "Masseur is loaded!");
          return {
            data: response.data,
            errorMessage: null,
          };
        })
        .catch((error) => {
          setIsLoading(false);
          onError(error, showToast);
          return {
            data: null,
            errorMessage: error.response.data.message,
          };
        });
    } catch (err) {
      return {
        data: null,
        errorMessage: "Server Error!",
      };
    }
  }

  return {
    masseur,
    masseurAds,
    masseurProfile,
    isLoading,
    masseurAd,
  };
}
