import React from "react";
import AddRateModal from "../components/AddRateModal";
import { useTranslation } from "react-i18next";

export default function RateTableHeader({ fetchRates }) {
  const { t } = useTranslation();

  const rate = {
    id: null,
    price: null,
    time_duration: null,
    service_id: null,
    rate_type: "Rate Type",
    description: "",
  };
  return (
    <section className="rate__tablecontainer__tableheader__container">
      <div className="rate__tablecontainer__tableheader__container__title">
        <h4>{t("masseur.myRate.rateHeader")}</h4>
      </div>
      <div className="rate__tablecontainer__tableheader__container__buttonbox">
        <AddRateModal
          title={"Add New Rate"}
          fetchRates={fetchRates}
          rateParams={rate}
        />
      </div>
    </section>
  );
}
