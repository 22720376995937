import { store } from "../../redux/store";
import { masseurLogoutSuccess } from "../../redux/slices/auth/masseur";
export const onError = function (error, showToast) {
  if (error.response) {
    if (error.response.status == 401 || error.response.status == 404) {
      store.dispatch(masseurLogoutSuccess());
      showToast({ message: "Unauthorized User!", flag: "error" });
      return;
    }
    if (error.response.data)
      showToast({ message: error.response.data.message, flag: "error" });
    return;
  } else {
    console.error("Error Message:", error.message);
  }
  return error;
};

export const onSuccess = function (response, showToast, message) {
  showToast({ message: message, flag: "success" });
  return response.data;
};

export const formDataToObject = (formData) => {
  const object = {};
  formData.forEach((value, key) => {
    object[key] = value;
  });
  return object;
};
