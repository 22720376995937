import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/general/Loading";
import ServiceHourApis from "../../../../../apis/masseur/serviceHourApis";
import EditServiceHourModal from "./components/EditServiceHourModal";
import { useTranslation } from "react-i18next";

export default function SpecialHourTab({ refetch, setRefetch }) {
  const [serviceHour, setServiceHour] = useState([]);
  const { isLoading, getServiceHour } = ServiceHourApis();
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();

  const fetchServiceHours = async () => {
    const response = await getServiceHour("special_hours");

    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setServiceHour(response.data);
    }
  };

  useEffect(() => {
    fetchServiceHours();
  }, [refetch]);

  if (isLoading) return <Loading />;
  if (errorMessage) return null;

  return (
    <React.Fragment>
      <>
        {serviceHour.length > 0 ? (
          <table className="serviceHour__table">
            {serviceHour.map((data) => (
              <div className="serviceHour__table__container">
                <h3 className="serviceHour__table__days">{data.day}</h3>
                <h4 className="serviceHour__table__title">
                  {t("client.title.From")}
                </h4>
                <div className="serviceHour__table__textField">
                  <div className="serviceHour__table__textField__greenInput">
                    <label>{t("client.title.Time")}</label>
                    <div className="serviceHour__table__textField__greenInput__inputBox">
                      <input type="time" value={data.from_time} />
                    </div>
                  </div>
                </div>
                <h4 className="serviceHour__table__title">
                  {t("client.title.To")}
                </h4>
                <div className="serviceHour__table__textField">
                  <div className="serviceHour__table__textField__greenInput">
                    <label>{t("client.title.Time")}</label>
                    <div className="serviceHour__table__textField__greenInput__inputBox">
                      <input type="time" value={data.to_time} />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <EditServiceHourModal
                    data={data}
                    setRefetch={setRefetch}
                    refetch={refetch}
                  />
                </div>
              </div>
            ))}
          </table>
        ) : (
          <h1 className="noServiceHour_title">
            {t("client.title.CreateServiceHour")}
          </h1>
        )}
      </>
      <section className="responsivServiceHour">
        {serviceHour.length > 0 ? (
          serviceHour.map((data) => (
            <aside className="responsivServiceHour__container">
              <div className="responsivServiceHour__container__top">
                <h3 className="serviceHour__table__days">{data.day}</h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                >
                  <EditServiceHourModal
                    data={data}
                    setRefetch={setRefetch}
                    refetch={refetch}
                  />
                </div>
              </div>
              <label className="responsivServiceHour__container__label">
                {t("client.title.Date")}
              </label>
              <div className="serviceHour__table__textField">
                <div className="serviceHour__table__textField__greenInput">
                  <label> {t("client.title.AddDate")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="date" value={data.date} />
                  </div>
                </div>
              </div>
              <label className="responsivServiceHour__container__label">
                {t("client.title.Time")}
              </label>
              <div className="responsivServiceHour__container__time">
                <div className="serviceHour__table__textField">
                  <div className="serviceHour__table__textField__greenInput">
                    <label>{t("client.title.From")}</label>
                    <div className="serviceHour__table__textField__greenInput__inputBox">
                      <input type="time" value={data.from_time} />
                    </div>
                  </div>
                </div>
                <div className="serviceHour__table__textField">
                  <div className="serviceHour__table__textField__greenInput">
                    <label>{t("client.title.To")}</label>
                    <div className="serviceHour__table__textField__greenInput__inputBox">
                      <input type="time" value={data.to_time} />
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          ))
        ) : (
          <h1 className="createServiceHour_title">
            {t("client.title.CreateServiceHour")}
          </h1>
        )}
      </section>
    </React.Fragment>
  );
}
