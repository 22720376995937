import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";
import { Cookie } from "@mui/icons-material";

export default function ServiceHourApis() {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { masseur } = useSelector((state) => state.masseurAuth);
  const { language } = useSelector((state) => state.i18n);

  async function getServiceHour(serviceHour) {
    setIsLoading(true);
    return await axios
      .get(
        `${SERVER_API}/masseurs/service_hours?service_type=${serviceHour}&locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsLoading(false);
        return {
          data: [],
          errorMessage: error.response.data.message,
        };
      });
  }

  async function addServiceHour(serviceHour) {
    return await axios
      .post(
        `${SERVER_API}/masseurs/service_hours?locale=${language.id}`,
        {
          service_hour: {
            ...serviceHour,
            masseur_detail_id: masseur.masseur_detail.id,
          },
        },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => {
        showToast({ message: response.data.message, flag: "success" });
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        showToast({ message: error.message, flag: "error" });
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateServiceHour(serviceHour) {
    return await axios
      .put(
        `${SERVER_API}/masseurs/service_hours/${serviceHour.id}?locale=${language.id}`,
        {
          service_hour: serviceHour,
        },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => {
        showToast({ message: response.data.message, flag: "success" });
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        showToast({ message: error.message, flag: "error" });
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }
  async function deleteServiceHour(id) {
    try {
      setIsLoading(true);

      const response = await axios.delete(
        `${SERVER_API}/masseurs/servicehour/${id}`,
        {
          headers: {
            Authorization: Cookie.get("masseur-access-token"),
          },
        }
      );

      setIsLoading(false);
      showToast({ message: response.data.message, flag: "success" });
    } catch (error) {
      setIsLoading(false);
      showToast({
        message: error.response?.data?.message || "An error occurred",
        flag: "error",
      });
      console.error(error);
    }
  }
  return {
    addServiceHour,
    getServiceHour,
    updateServiceHour,
    isLoading,
  };
}
