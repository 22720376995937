import React, { useState, useEffect, useMemo } from "react";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import {
  WorkspacePremium as WorkspacePremiumIcon,
  LocationOn as LocationOnIcon,
  NavigateBefore as NavigateBeforeIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

const AdCard = ({ ad, travelAd }) => {
  const { t } = useTranslation();
  const [disable, setDisabled] = useState(false);
  const navigate = useNavigate();

  const editPath = useMemo(
    () =>
      travelAd ? `/masseur/travel-ads/${ad.id}/edit` : `/masseur/ads/${ad.id}`,
    [travelAd]
  );

  useEffect(() => {
    setDisabled(ad.ad_photos.length <= 3);
  }, [ad]);

  return (
    <section className="myAds__body__container2__adsContainer2__adCard">
      <div className="myAds__body__container2__adsContainer2__adCard__profile">
        <figure className="myAds__body__container2__adsContainer2__adCard__profile__image">
          <img
            src={ad.profile_image || require("../../assets/Profile_Image.jpg")}
            alt=""
          />
          <WorkspacePremiumIcon className="myAds__body__container2__adsContainer2__adCard__profile__image__icon" />
        </figure>

        <div className="myAds__body__container2__adsContainer2__adCard__profile__nameBox">
          <div className="flex__item">
            <h3>{ad.masseur.screen_name}</h3>
            <div className="ad__editIcon">
              <EditIcon onClick={() => navigate(editPath)} />
            </div>
          </div>

          <span>Silver</span>
          <h5 className="myAds__body__container2__adsContainer2__adCard__profile__nameBox__availability">
            {t("masseur.myAd.Availability")} <b>Mon-Tue</b>
          </h5>
        </div>
      </div>
      <div className="myAds__body__container2__adsContainer2__adCard__services">
        <h4>{t("masseur.myAd.Services")}</h4>
        <div className="myAds__body__container2__adsContainer2__adCard__services__options">
          {ad.services.map((service) => (
            <h5>{service.name}</h5>
          ))}
          {ad.massage_types.map((massage) => (
            <h5>{massage.name}</h5>
          ))}
        </div>
        <div className="myAds__body__container2__adsContainer2__adCard__services__address">
          <LocationOnIcon className="myAds__body__container2__adsContainer2__adCard__services__address__icon" />
          <b>{ad.ad_address}</b>
        </div>
      </div>
      <div className="myAds__body__container2__adsContainer2__adCard__details">
        <div className="myAds__body__container2__adsContainer2__adCard__details__time">
          <h4>
            {t("masseur.myAd.Minutes")}: {ad.time_duration}{" "}
            {t("masseur.myAd.mins")}
          </h4>
          <h4>Incall/OutCall</h4>
          {/* <p>Regular 2 hours</p> */}
        </div>
        <div className="myAds__body__container2__adsContainer2__adCard__details__price">
          <h5>{t("masseur.myAd.Startingat")}</h5>
          <b>${ad.starting_price}</b>
        </div>
      </div>
      <footer className="myAds__body__container2__adsContainer2__adCard__images">
        <header className="myAds__body__container2__adsContainer2__adCard__images__header">
          <h4>{t("masseur.myAd.Images")}</h4>
          <div className="myAds__body__container2__adsContainer2__adCard__images__header__icons">
            <IconButton disabled={disable} id={`prevButton-${ad.id}`}>
              <NavigateBeforeIcon
                className="myAds__body__container2__adsContainer2__adCard__images__header__icons__icon NavigateBeforeIcon "
                sx={{
                  backgroundColor: disable ? "gray !important" : "",
                }}
              />
            </IconButton>
            <IconButton disabled={disable} id={`nextButton-${ad.id}`}>
              <KeyboardArrowRightIcon
                className="myAds__body__container2__adsContainer2__adCard__images__header__icons__icon"
                sx={{
                  backgroundColor: disable ? "gray !important" : "",
                }}
              />
            </IconButton>
          </div>
        </header>
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={3}
          className="myAds__body__container2__adsContainer2__adCard__images__imageBox"
          navigation={{
            prevEl: `#prevButton-${ad.id}`,
            nextEl: `#nextButton-${ad.id}`,
          }}
          loop={true}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          {ad.ad_photos.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image.image_url} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </footer>
    </section>
  );
};

export default AdCard;
