import React, { useState } from "react";
import CardOptions from "./CardOptions";
import BasicCardModal from "./BasicCardModal";
import { useTranslation } from "react-i18next";
import DoneAllIcon from "@mui/icons-material/DoneAll";
const BasicCard = ({ levelPackage, subscription }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <section>
      <BasicCardModal
        handleClose={handleClose}
        open={open}
        levelPackage={levelPackage}
      />
      <div
        className="selectLevel__body__cards__card selectLevel__body__cards__cardBasic"
        onClick={handleOpen}
      >
        <h3 className="selectLevel__body__cards__card__heading selectLevel__body__cards__card__headingBasic">
          {levelPackage.package_type}
        </h3>
        {subscription.package_id == levelPackage.id && (
          <div
            style={{
              color: "green",
            }}
          >
            <DoneAllIcon />
          </div>
        )}
        <section className="selectLevel__body__cards__card__body">
          <aside className="selectLevel__body__cards__card__body__textBox">
            <CardOptions
              description={t("masseur.myLevel.uploadImages", {
                images_limit: levelPackage.images_limit,
              })}
            />
            <CardOptions
              description={t("masseur.myLevel.uploadVideos", {
                videos_limit: levelPackage.video_count,
              })}
            />
            {/* <CardOptions description={t("masseur.myLevel.availabilityFlag")} /> */}
            <CardOptions
              description={t("masseur.myLevel.travelAd", {
                travel_ad: levelPackage.travel_ad,
              })}
            />
            <CardOptions
              description={t("masseur.myLevel.localAd", {
                local_ad: levelPackage.local_ad,
              })}
            />
            <CardOptions
              description={t("masseur.myLevel.serviceHourSchedule", {
                service_hour_schedule: levelPackage.service_hour_schedule,
              })}
            />
            <CardOptions
              description={t("masseur.myLevel.serviceAndMassageType", {
                service_and_massage_types:
                  levelPackage.service_and_massage_types,
              })}
            />
            <CardOptions description={t("masseur.myLevel.emailSupport")} />

            <CardOptions description={t("masseur.myLevel.cancelAnytime")} />
          </aside>
          <div className="selectLevel__body__cards__card__body__trial selectLevel__body__cards__card__body__trialBasic">
            <p>
              (${levelPackage.duration_frequency["1"]}/
              {t("masseur.myLevel.month")}
              {"   "}
              {levelPackage.duration_frequency["3"]}/3{"    "}
              {t("masseur.myLevel.month")}{" "}
              {levelPackage.duration_frequency["6"]}/ 6 {"   "}
              {t("masseur.myLevel.month")})
            </p>
            {/* <h5> {t("masseur.myLevel.recurring")}</h5> */}
          </div>
          <div className="selectLevel__body__cards__card__body__divider">
            <p>{t("masseur.myLevel.or")}</p>
          </div>
          <aside className="selectLevel__body__cards__card__body__trial2 selectLevel__body__cards__card__body__trialBasic">
            <p>
              {" "}
              {levelPackage.duration_frequency["12"]}{" "}
              {t("masseur.myLevel.perYear")}
            </p>
            <h5>{t("masseur.myLevel.oneTimePayment")}</h5>
            <h5 className="selectLevel__body__cards__card__body__trial2__saving">
              {t("masseur.myLevel.saving")} <br /> {levelPackage.savePercentage}
              %
            </h5>
          </aside>
        </section>
      </div>
    </section>
  );
};

export default BasicCard;
