import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import GeneralApis from "../../../../../apis/GeneralApis";
import { useSelector } from "react-redux";
import Loading from "../../../../../components/general/Loading";

const SupportForm = () => {
  const { t } = useTranslation();
  const { masseur } = useSelector((state) => state.masseurAuth);
  const { isApiLoading, sendSupportEmail } = GeneralApis();

  const editorRef = useRef(null);
  const [support, setSupport] = useState({
    name: masseur.screen_name,
    email: masseur.email,
    subject: "",
    body: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSupport((prevSupport) => ({
      ...prevSupport,
      [name]: value,
    }));
  };

  const handleEditorChange = (content, editor) => {
    setSupport((prevSupport) => ({
      ...prevSupport,
      body: content,
    }));
  };

  const handleSendButtonClick = async () => {
    await sendSupportEmail(support);
  };

  if (isApiLoading) return <Loading />;

  return (
    <>
      {/* <section className="support__form__searchBox"> */}
      {/* <div className="support__form__searchBox__search">
          <label className="support__form__searchBox__search__searchLabel">
            {t("masseur.support.to")}
          </label>
          <input
            type="text"
            placeholder={t("masseur.support.typeHere")}
            className="support__form__searchBox__search__searchInput"
            onChange={(e) => handleInputChange(e)}
            value={support.to} gfddxc≈hbmvvb 
            name="to"
          />
        </div> */}
      {/* </section> */}
      <section className="support__form__searchBox">
        <div className="support__form__searchBox__search">
          <label className="support__form__searchBox__search__searchLabel">
            {t("masseur.support.subject")}
          </label>
          <input
            name="subject"
            type="text"
            placeholder={t("masseur.support.typeHere")}
            className="support__form__searchBox__search__searchInput"
            value={support.subject}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
      </section>

      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey={"yn8hznqzfzs4rre9zarohcteunl838sugjqc5x68nci0ogpx"}
        initialValue={support.paragraph}
        init={{
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onChange={(e) => handleEditorChange(e.target.getContent())}
      />

      {/* Button to Send Support Form */}
      <div className="support__form__button">
        <Button
          variant="contained"
          size="small"
          className="support__form__button__sendButton"
          onClick={handleSendButtonClick}
        >
          {t("masseur.support.next")}
        </Button>
      </div>
    </>
  );
};

export default SupportForm;
