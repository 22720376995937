import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";

export default function ReviewsApis() {
  const { showToast } = useToast();
  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getReviews(masseur_slug) {
    setIsReviewLoading(true);
    return await axios
      .get(
        `${SERVER_API}/clients/home/masseur_reviews?locale=${language.id}&masseur_slug=${masseur_slug}`
      )
      .then((response) => response)
      .then((response) => {
        setIsReviewLoading(false);
        onSuccess(response, showToast, "Reveiws are fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsReviewLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function addReview(review) {
    setIsReviewLoading(true);
    const params = {
      review: review,
      masseur_slug: review.masseur_slug,
    };
    return await axios
      .post(`${SERVER_API}/clients/reviews`, params, {
        headers: {
          Authorization: Cookies.get("client-access-token"),
        },
      })
      .then((response) => {
        setIsReviewLoading(false);
        onSuccess(response, showToast, "Added new review!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsReviewLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    addReview,
    isReviewLoading,
    getReviews,
  };
}
