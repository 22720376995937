import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import GreenInput from "../../../../../components/Form/GreenInput";
import GreenSelect from "../../../../../components/Form/GreenSelect";
import ProfileApis from "../../../../../apis/masseur/ProfileApis";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../components/general/Loading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BlueButton from "../../../../../components/Form/BlueButton";

const schema = yup.object().shape({
  street: yup.string().required("Street is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State unit is required"),
  zip: yup.number().required("Zip is required"),
  country: yup.string().required("Country is required"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  width: 440,
};

const optionValues = [
  { label: "Select Country", value: "" },
  {
    label: "USA",
    value: "USA",
  },
  {
    label: "MX",
    value: "MX",
  },
];

export default function EditAddress({ setProfile, masseur }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [address, setAddress] = useState({
    street: masseur?.street,
    city: masseur?.city,
    state: masseur?.state,
    zip: masseur?.zip,
    country: masseur?.country,
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      street: masseur.street,
      city: masseur.city,
      state: masseur.state,
      zip: masseur.zip,
      country: masseur.country,
    },
  });
  const { isProfileLoading, updateMasseurAddress } = ProfileApis();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async (data) => {
    setSubmitLoader(true);
    const response = await updateMasseurAddress(data);
    if (response) {
      setProfile(response);
      handleClose();
    }
    setSubmitLoader(false);
  };

  return (
    <main>
      <header
        className="masseur__profilesetting__section__box__container"
        onClick={handleOpen}
      >
        <h4 className="masseur__profilesetting__section__box__container__title">
          {t("masseur.myProfile.editAddress")}
        </h4>
        <div className="masseur__profilesetting__section__box__container__icon">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      </header>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          {isProfileLoading ? (
            <Loading />
          ) : (
            <div>
              <header className="modal__container">
                <div className="modal__container__icon" onClick={handleClose}>
                  <ArrowBackIosIcon />
                </div>
                <h4 className="passwordChange__container__passChange">
                  {t("masseur.myProfile.editLocation")}
                </h4>
              </header>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h3>{t("masseur.myProfile.address")}</h3>
                <GreenInput
                  name="street"
                  handleChange={handleChange}
                  label={t("masseur.myProfile.streetaddress")}
                  placeholder={"Type here..."}
                  type={"text"}
                  register={register("street")}
                  error={errors.street?.message}
                />
                <section className="profileSetup__container__body__left__inputBox">
                  <div>
                    <GreenInput
                      name="city"
                      handleChange={handleChange}
                      label={t("masseur.myProfile.city")}
                      placeholder={"Type here..."}
                      type={"text"}
                      register={register("city")}
                      error={errors.city?.message}
                    />
                  </div>
                  <div>
                    <GreenInput
                      name="state"
                      handleChange={handleChange}
                      label={t("masseur.myProfile.state")}
                      placeholder={"Type here..."}
                      type={"text"}
                      register={register("state")}
                      error={errors.state?.message}
                    />
                  </div>
                </section>
                <section className="profileSetup__container__body__left__inputBox">
                  <div>
                    <GreenInput
                      name="zip"
                      handleChange={handleChange}
                      label={t("masseur.myProfile.zip")}
                      placeholder={"Type here..."}
                      type={"number"}
                      register={register("zip")}
                      error={errors.zip?.message}
                    />
                  </div>
                  <div>
                    <GreenSelect
                      name="country"
                      label={t("masseur.myProfile.country")}
                      options={optionValues}
                      handleChange={handleChange}
                      register={register("country")}
                      error={errors.country?.message}
                    />
                  </div>
                </section>
                <BlueButton
                  loading={submitLoader}
                  variant="contained"
                  className="passwordChange__container__saveButton"
                  onClick={handleSubmit(onSubmit)}
                  title={t("masseur.myProfile.saveChanges")}
                />
              </form>
            </div>
          )}
        </Box>
      </Modal>
    </main>
  );
}
