import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import clientReducer from "../slices/auth/client";
import masseurReducer from "../slices/auth/masseur";
import { persistStore, persistReducer } from "redux-persist";
import sidebarSlice from "../slices/sidebarSlice";
import languageSlice from "../slices/languageSlice";
import locationSlice from "../slices/locationSlice";
import subscriptionSlice from "../slices/subscription";
import storage from "redux-persist/lib/storage";

const clientPersistConfig = {
  key: "client",
  storage,
};

const masseurPersistConfig = {
  key: "masseur",
  storage,
};

const sidebarPersistConfig = {
  key: "sidebar",
  storage,
};

const languagePersistConfig = {
  key: "language",
  storage,
};
const locationPersistConfig = {
  key: "location",
  storage,
};

const subscriptionPersistConfig = {
  key: "subscription",
  storage,
};

const locationPersistReducer = persistReducer(
  locationPersistConfig,
  locationSlice
);

const languagePersistReducer = persistReducer(
  languagePersistConfig,
  languageSlice
);
const clientPersistReducer = persistReducer(clientPersistConfig, clientReducer);
const masseurPersistReducer = persistReducer(
  masseurPersistConfig,
  masseurReducer
);

const sidebarPersistReducer = persistReducer(
  sidebarPersistConfig,
  sidebarSlice
);

const subscriptionPersistReducer = persistReducer(
  subscriptionPersistConfig,
  subscriptionSlice
);

const store = configureStore({
  reducer: {
    clientAuth: clientPersistReducer,
    masseurAuth: masseurPersistReducer,
    sidebar: sidebarPersistReducer,
    i18n: languagePersistReducer,
    currentLocation: locationPersistReducer,
    subscription: subscriptionPersistReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

const persistor = persistStore(store);

export { store, persistor };
