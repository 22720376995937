import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  masseur: null,
  isMasseurAuthenticated: false,
};

export const MasseurSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    masseurData(state, action) {
      const { masseur } = action.payload;
      state.masseur = masseur;
    },
    masseurLoginSuccess(state, action) {
      const { masseur, token } = action.payload;
      state.masseur = masseur;
      state.isMasseurAuthenticated = true;
      Cookies.set("masseur-access-token", token, { expires: 1 });
    },
    masseurLogoutSuccess(state) {
      state.masseur = null;
      state.isMasseurAuthenticated = false;
      Cookies.remove("masseur-access-token");
    },
  },
});

export const { masseurLoginSuccess, masseurLogoutSuccess, masseurData } =
  MasseurSlice.actions;
export default MasseurSlice.reducer;
