import React from "react";
import { useTranslation } from "react-i18next";

export default function MyBatch() {
  const { t } = useTranslation();

  const Batch = (
    <section className="mybatch__container__box">
      <figure className="mybatch__container__box__image">
        <img src={require("../../../../assets/badge-1361.png")} />
      </figure>
      <h4 className="mybatch__container__box__title">
        {t("masseur.myBatch.paragraph")}
      </h4>
      <h4 className="mybatch__container__box__messsauretitle">
        {t("masseur.myBatch.name")}
      </h4>
      <h4 className="mybatch__container__box__servicetitle">
        {t("masseur.myBatch.service")}
      </h4>
      <div className="mybatch__container__box__container">
        <h4 className="mybatch__container__box__container__title">
          {t("masseur.myBatch.congratulation")}
        </h4>
        <h4 className="mybatch__container__box__container__paragraph">
          {t("masseur.myBatch.description")}
        </h4>
      </div>
    </section>
  );
  return (
    <section className="mybatch">
      <h1 className="rate__tablecontainer__tableheader__container__title">
        {t("masseur.myBatch.myBatchHeader")}
      </h1>
      <section className="mybatch__container">
        {Array(5)
          .fill()
          .map((_, index) => (
            <Batch key={index} />
          ))}
      </section>
    </section>
  );
}
