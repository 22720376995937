import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Box, Modal } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProfileApis from "../../../../../apis/masseur/ProfileApis";
import { useTranslation } from "react-i18next";
import GeneralApis from "../../../../../apis/GeneralApis";
import Loading from "../../../../../components/general/Loading";
import GreenMultiSelect from "../../../../../components/Form/GreenMultiSelect";
import GreenSelect from "../../../../../components/Form/GreenSelect";
import BlueButton from "../../../../../components/Form/BlueButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  width: "30%",
};
export default function EditDemographics({ profile, setProfile }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { isProfileLoading, updateMasseurDemographics } = ProfileApis();
  const { isApiLoading, getDemographics } = GeneralApis();

  const [open, setOpen] = useState(false);
  const [demographics, setDemographics] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOpen = () => {
    setOpen(true);
    fetchDemographics();
    if (errorMessage) return null;
  };
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleMultiSelectChange = (data, name) => {
    const ids = data.map((service) => service.value);
    setProfile({ ...profile, [name]: ids });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const demographic = {
      language_ids: profile.language_ids,
      work_location_ids: profile.work_location_ids,
      work_surface_ids: profile.work_surface_ids,
      ethnicity_id: profile.ethnicity_id,
      sexual_orientation_id: profile.sexual_orientation_id,
      smoking_frequency_id: profile.smoking_frequency_id,
      drug_frequency_id: profile.drug_frequency_id,
    };

    const response = await updateMasseurDemographics(demographic);
    if (response.data) {
      handleClose();
      setProfile(response.data);
    }
    setLoading(false);
  };

  const fetchDemographics = async () => {
    const response = await getDemographics();
    if (response.data) {
      setDemographics(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const getMultiSelectValue = useCallback(
    (name, values = []) => {
      if (demographics !== null && values.length > 0) {
        return demographics[`${name}`].filter((item) =>
          values.includes(item.value)
        );
      } else {
        return [];
      }
    },
    [profile, demographics]
  );
  return (
    <main>
      <header
        className="masseur__profilesetting__section__box__container"
        onClick={handleOpen}
      >
        <h4 className="masseur__profilesetting__section__box__container__title">
          {t("masseur.myProfile.update_demographics")}
        </h4>
        <div className="masseur__profilesetting__section__box__container__icon">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      </header>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          {isApiLoading || isProfileLoading || demographics == null ? (
            <Loading />
          ) : (
            <div>
              <section className="modal__container">
                <div className="modal__container__icon" onClick={handleClose}>
                  <ArrowBackIosIcon />
                </div>
                <h4 className="passwordChange__container__passChange">
                  {t("masseur.myProfile.update_demographics")}
                </h4>
              </section>
              <GreenSelect
                name="ethnicity_id"
                value={profile.ethnicity_id}
                label={t("masseur.myProfile.ethnicity")}
                options={demographics.ethnicities || []}
                handleChange={handleChange}
              />
              <GreenSelect
                name="sexual_orientation_id"
                value={profile.sexual_orientation_id}
                label={t("masseur.myProfile.sexualOrientation")}
                options={demographics.sexual_orientations || []}
                handleChange={handleChange}
              />
              <GreenSelect
                name="smoking_frequency_id"
                value={profile.smoking_frequency_id}
                label={t("masseur.myProfile.smokingFrequency")}
                options={demographics.smoking_frequencies || []}
                handleChange={handleChange}
              />
              <GreenSelect
                name="drug_frequency_id"
                value={profile.drug_frequency_id}
                label={t("masseur.myProfile.drugFrequency")}
                options={demographics.drug_frequencies || []}
                handleChange={handleChange}
              />
              <GreenMultiSelect
                label={t("masseur.myProfile.language")}
                options={demographics.languages || []}
                name="language_ids"
                value={getMultiSelectValue("languages", profile.language_ids)}
                handleChange={handleMultiSelectChange}
              />
              <GreenMultiSelect
                label={t("masseur.myProfile.workLocation")}
                options={demographics.work_locations || []}
                name="work_location_ids"
                value={getMultiSelectValue(
                  "work_locations",
                  profile.work_location_ids
                )}
                handleChange={handleMultiSelectChange}
              />
              <GreenMultiSelect
                label={t("masseur.myProfile.workSurface")}
                options={demographics.work_surfaces || []}
                name="work_surface_ids"
                value={getMultiSelectValue(
                  "work_surfaces",
                  profile.work_surface_ids
                )}
                handleChange={handleMultiSelectChange}
              />
              <BlueButton
                onClick={handleSubmit}
                loading={loading}
                variant="contained"
                className="passwordChange__container__saveButton"
                title={t("masseur.myProfile.saveChanges")}
              />
            </div>
          )}
        </Box>
      </Modal>
    </main>
  );
}
