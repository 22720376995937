import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function MasseurProfileSetupRoute({ children }) {
  const { masseur, isMasseurAuthenticated } = useSelector(
    (state) => state.masseurAuth
  );
  const access_token = Cookies.get("masseur-access-token");
  const navigate = useNavigate();

  if (isMasseurAuthenticated && access_token && masseur) {
    return children;
  } else if (
    isMasseurAuthenticated &&
    access_token &&
    masseur &&
    masseur.active
  ) {
    navigate("/masseur/profile");
    return;
  } else {
    navigate("/signin");
  }
}
