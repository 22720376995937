import React from "react";
import { useTranslation } from "react-i18next";

export default function RateTab({ services, rates }) {
  const { t } = useTranslation();

  const getServiceNameById = (id) =>
    services.find((service) => service.id == id)?.name;

  return (
    <main className="rate__tablecontainer__table__section">
      {rates.length > 0 ? (
        rates.map((rate) => (
          <section className="rate__tablecontainer__table__section__container">
            <aside className="rate__tablecontainer__table__section__container__dollartextfield">
              <section className="rate__tablecontainer__table__section__container__dollartextfield__greenInput">
                <label>{t("masseur.myRate.price")}</label>
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                  <input value={`$ ${rate.price}`} />
                </div>
              </section>
            </aside>
            <section className="rate__tablecontainer__table__section__container__dollartextfield">
              <aside className="rate__tablecontainer__table__section__container__dollartextfield__greenInput">
                <label>{t("masseur.myRate.time")}</label>
                <section className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                  <icon className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox__icon"></icon>
                  <input value={rate.time_duration} />
                </section>
              </aside>
            </section>

            <aside className="rate__tablecontainer__table__section__container__Regularbox">
              <section className="greenSelect">
                <label>{t("masseur.myRate.description")}</label>
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                  <input value={rate.description} />
                </div>
              </section>
            </aside>
            <aside className="rate__tablecontainer__table__section__container__Regularbox">
              <section className="greenSelect">
                <label>{t("masseur.myRate.rateType")}</label>
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                  <input value={rate.rate_type} />
                </div>
              </section>
            </aside>
            <aside className="rate__tablecontainer__table__section__container__Regularbox">
              <section className="greenSelect">
                <label>{t("masseur.myRate.service")}</label>
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                  <input value={getServiceNameById(rate.service_id)} />
                </div>
              </section>
            </aside>
          </section>
        ))
      ) : (
        <h3 className="noVideo__title">{t("client.title.NoRateinthisAd")}</h3>
      )}
    </main>
  );
}
