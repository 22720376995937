import React, { useState } from "react";
import Button from "@mui/material/Button";
import ServiceHourApis from "../../../../../../apis/masseur/serviceHourApis";
import ModalForm from "./ModalForm";
import { useTranslation } from "react-i18next";

export default function AddServiceHourModal({ title, setRefetch, refetch }) {
  const { t } = useTranslation();

  const { isLoading, addServiceHour } = ServiceHourApis();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [serviceHour, setServiceHour] = useState({
    id: null,
    day: null,
    holiday: null,
    from_time: "",
    to_time: "",
    service_type: "special_hours",
  });

  return (
    <section
      style={{
        zIndex: "999",
      }}
    >
      <Button className="serviceHour__buttonBox__button" onClick={handleOpen}>
        {t("masseur.serviceHour.next")}
      </Button>

      <ModalForm
        serviceHour={serviceHour}
        setServiceHour={setServiceHour}
        open={open}
        setOpen={setOpen}
        setRefetch={setRefetch}
        refetch={refetch}
      />
    </section>
  );
}
