import React from "react";
import { Button } from "@mui/base";
import SupportForm from "./components/SupportForm";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();

  return (
    <main className="support">
      <header className="support__container">
        <h1 className="support__container__title">
          {t("masseur.support.supportHeader")}
        </h1>
        {/* <Button className="support__container__composeButton">
          {t("masseur.support.compose")}
        </Button> */}
      </header>
      <section className="support__form">
        <SupportForm />
      </section>
    </main>
  );
};

export default Support;
