import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

export default function NavDrawer() {
  const { t } = useTranslation();
  const nameArray = [
    {
      name: t("client.adsProfile.dashboard"),
    },
    {
      name: t("client.adsProfile.favourite"),
    },
    {
      name: t("client.adsProfile.service"),
    },
  ];
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <img
          src={require("../../../assets/logo.jpeg")}
          alt=""
          style={{ width: "200px", objectFit: "cover", padding: "2rem 0" }}
        />
      </List>
      <Divider />
      <List
        style={{
          backgroundColor: "#193D66",
          height: "100%",
          minHeight: "100vh",
          color: "#E6EAEE",
          paddingLeft: "1rem",
        }}
      >
        {nameArray.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon
              style={{
                color: "#7FC550",
              }}
            />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
