import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

export default function ReviewApis() {
  const { showToast } = useToast();
  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getReviews() {
    setIsReviewLoading(true);
    return await axios
      .get(`${SERVER_API}/masseurs/reviews?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((reponse) => reponse)
      .then((response) => {
        setIsReviewLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsReviewLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateReview(review) {
    setIsReviewLoading(true);
    return await axios
      .put(
        `${SERVER_API}/masseurs/reviews/${review.id}?locale=${language.id}`,
        { masseur_approved: review.masseur_approved },
        {
          headers: {
            Authorization: Cookies.get("masseur-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsReviewLoading(false);
        onSuccess(response, showToast, "Data Updated!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsReviewLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function deleteReview(id) {
    setIsReviewLoading(true);
    return await axios
      .delete(`${SERVER_API}/masseurs/reviews/${id}`, {
        headers: {
          Authorization: Cookies.get("masseur-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Review is deleted successfully");
        setIsReviewLoading(false);
        return {
          data: true,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsReviewLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getReviews,
    updateReview,
    deleteReview,
    isReviewLoading,
  };
}
