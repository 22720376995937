import React, { useEffect, useState } from "react";
import ProfileSetting from "./ProfileSetting/index";
import ProfileInformation from "./ProfileInformation";
import ProfileApis from "../../../../apis/masseur/ProfileApis";
import Loading from "../../../../components/general/Loading";
import Popup from "./components/Popup";

export default function Profile() {
  const { getProfile, isProfileLoading, masseurSignOut } = ProfileApis();

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchProfileData = async () => {
    const response = await getProfile();
    if (response.error) {
      setErrorMessage(response.errorMessage);
    } else {
      setProfile(response.data);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (profile) {
      setLoading(false);
    }
  }, [profile]);
  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   setOpen(true);
  // }, []);

  if (errorMessage) return null;
  return (
    <main className="masseur">
      {isProfileLoading || loading ? (
        <div className="circularLoading">
          <Loading />
        </div>
      ) : (
        <>
          <section className="masseur__container">
            <ProfileInformation
              profile={profile}
              setProfile={setProfile}
              setErrorMessage={setErrorMessage}
            />
          </section>
          <section className="masseur__profilesetting">
            <ProfileSetting
              setProfile={setProfile}
              profile={profile}
              masseurSignOut={masseurSignOut}
              isProfileLoading={isProfileLoading}
            />
          </section>
          <Popup open={open} handleClose={handleClose} />
        </>
      )}
    </main>
  );
}
